import {
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import CallSplitIcon from "@material-ui/icons/CallSplit";
import RefreshIcon from "@material-ui/icons/Refresh";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import NotesIcon from "@material-ui/icons/Notes";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ReorderIcon from "@material-ui/icons/Reorder";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import BarChartIcon from "@material-ui/icons/BarChart";
import PopUpIconButton from "../../components/PopUpIconButton/PopUpIconButton.js";
import AIIcon from "../Icons/AIIcon.js";

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { handleShortCutKey } from "../../Functions/editorShortcut.js";
import {
  removeSuggestionsFromHTML,
  restoreCursor,
  saveCursor,
  CreateGuid,
} from "../../Functions/EditorFunction.js";
import Toolbar from "./Components/Toolbar.js";
import "./LiboEditor.css";
import "../../components/LiboBerryCss/liboBerry.css";
import "./libomath.css";
import "./EditorTemp.css";
import classes from "./LiboEditor.module.css";
import { headings } from "./Components/Headings.js";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import { ImageSizes } from "./ImageSizes.js";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  uploadProjectFileCopy,
  getAutoComplete,
} from "../../Requests/Requests.js";

import Citation from "./Components/FloatSuggestion/citation.js";
import Plagiarism from "./Components/FloatSuggestion/Plagiarism.js";
import { createPlagiarismSuggestionResult } from "../../Functions/FloatSuggestion.js";
import AiTyping from "./Components/AISuggestion/AiTyping.js";
import { AiTones } from "./AiTones.js";
import EnterIcon from "../Icons/EnterIcon.js";
import HeaderPreview from "./Components/HeaderPreview.js";
import {
  processDocument,
  extractFollowingDivs,
} from "./Components/CopyPaste.js";

let interval;

function LiboEditor(props) {
  const [pressEnterChange_tone, setPressEnterChange_tone] = useState(false);
  const { onChange, sugs, onSelectDivChange, plagiarismSugs } = props;
  const [sel, setSel] = useState(window.getSelection());
  const [selChanged, setSelChanged] = useState(0);
  const editorRef = useRef(null);
  const aISuggestion = useRef(null);
  const [toolbarIsOpenDropDownPanel, setToolbarIsOpenDropDownPanel] =
    useState(true);
  const [loaderAiSuggestion, setLoaderAiSuggestion] = useState(false);
  const [aiSuggestionPropperty, setAiSuggestionPropperty] = useState({
    left: "0px",
    top: "0px",
    showsubMenuLeft: true,
  });
  const [showPanelAi, setShowPanelAi] = useState(false); //
  const [showAiSuggestion, setShowAiSuggestion] = useState(false);
  const [selectdiv, setSelectdiv] = useState(null);
  const [imageEditProperties, setImageEditProperties] = useState({
    caption: "",
    label: "",
    size: "",
  });
  const [autoComplete, setAutoComplete] = useState([
    {
      intervalAiAutoComplete: null,
    },
  ]);

  const [showAiTone, setShowAiTone] = useState(false);
  const [showAIInterval, setShowAIInterval] = useState(null); //showAIInterval = null;
  const [isEditImageModelOpen, setIsEditImageModelOpen] = useState(false);
  const [PlagiarismProperty, setPlagiarismProperty] = useState({
    ShowPlagiarism: false,
    dataPlg: [],
    element: [],
    content: "",
  });
  const [citationProperty, setCitationProperty] = useState({
    ShowCitation: false,
    dataSug: [],
    element: [],
    content: "",
    citationIds: [],
  });
  const [aiElement, setAiElement] = useState([]);
  const [aiContent, setAiContent] = useState("");
  const [aiTitle, setAiTitle] = useState("");
  const [aiCaption, setAiCaption] = useState("");
  const [openFormula, setOpenFormula] = useState(null);
  const [aiLoading, setAiLoading] = useState(null);
  const [aiOpenPanel, setAiOpenPanel] = useState(false);
  const [aiType, setAiType] = useState(null);
  const [aiResult, setAiResult] = useState(null);
  const [disabledAiAutoComplete, setDisabledAiAutoComplete] = useState(true);
  const [aiTone, setAiTone] = useState(null);
  const [aiCustomTone, setAiCustomTone] = useState(null);
  const [iscontentEditable, SetIscontentEditable] = useState(true);
  const [dragElement, setDragElement] = useState([]);
  const [randomNumberForCloseFilter, setRandomNumberForCloseFilter] =
    useState(0);
  const [isOpenHeadersPanel, setIsOpenHeadersPanel] = useState(false);

  const handleClickHeadersPanel = () => {
    setIsOpenHeadersPanel(!isOpenHeadersPanel); // Toggle the panel visibility
  };

  /**
   * Handle the paste event to process clipboard content and insert into the editor.
   *
   * @param {Object} e - the event object containing the clipboard data
   * @return {Promise} a promise representing the completion of the paste handling
   */

  useEffect(() => {
    if (props.showPopupCititions) {
      props.setShowPopupCititions(false);
      let dataSug = [];
      let div_id = selectdiv?.id;
      if (selectdiv != null && selectdiv?.hasAttribute("data-sug")) {
        dataSug = JSON.parse(selectdiv.getAttribute("data-sug"));
      }
      let refIdList = getRefrenceCitation(selectdiv);
      let content = getTxtWithOutRefrence(selectdiv?.innerHTML).trim();
      if (!content || content == "") {
        content = getTxtWithOutRefrence(props.projectInfo?.title).trim();
      }
      let divIdcitation = citationProperty.element;
      if (divIdcitation.length == 0 || divIdcitation[0]?.id != div_id) {
        divIdcitation.pop();
        divIdcitation.push(selectdiv);
      }
      citationProperty.element = divIdcitation;
      setCitationProperty({
        ShowCitation: true,
        dataSug: dataSug,
        element: divIdcitation,
        content: content,
        citationIds: refIdList,
      });
    }
  }, [props.showPopupCititions]);
  async function handleCopy(e) {
    e.preventDefault();

    // Get the current selection
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return;

    // Extract the selected content
    const range = selection.getRangeAt(0);
    const selectedContent = range.cloneContents();

    // Create a temporary container for the selected content
    let tempContainer = document.createElement("div");
    tempContainer.appendChild(selectedContent);

    // Prepare the content for copying
    await prepareContentForCopy(tempContainer);

    // Convert the container's content back to a string
    const finalHTML = tempContainer.innerHTML;

    // Place the content into the clipboard
    e.clipboardData.setData("text/html", finalHTML);
    e.clipboardData.setData("text/plain", tempContainer.innerText);
  }

  async function prepareContentForCopy(doc) {
    // Remove editor-specific elements
    function removeEditorSpecificElements() {
      doc
        .querySelectorAll("[data-editor-specific]")
        .forEach((el) => el.remove());
    }

    // Inline CSS for tables to preserve formatting
    function inlineTableStyles() {
      doc.querySelectorAll("table").forEach((table) => {
        table.style.borderCollapse = "collapse";
        table.style.width = "100%";
        table.style.marginBottom = "20px";

        table.querySelectorAll("th, td").forEach((cell) => {
          cell.style.border = "1px solid black";
          cell.style.padding = "5px";
          cell.style.textAlign = "left";
        });
      });
    }

    // Ensure only titles are centered
    function centerTitles() {
      doc.querySelectorAll("[heading='title']").forEach((title) => {
        title.style.textAlign = "center";
        title.style.display = "block";
        title.style.margin = "20px 0";
      });
    }

    // removeEditorSpecificElements();

    inlineTableStyles();
    centerTitles();
    await wrapImagesForCopy(doc);
  }

  async function wrapImagesForCopy(doc) {
    const images = doc.querySelectorAll("img");
    images.forEach((img) => {
      const div = document.createElement("div");
      div.setAttribute("data-copied", "true");
      div.appendChild(img.cloneNode(true));
      img.parentNode.replaceChild(div, img);
    });
  }

  function flattenDivs() {
    // Select the article element
    const article = document.querySelector("article");
    if (!article) return;

    // Find the placeholder within the article
    const placeholder = article.querySelector('[placeholder="true"]');
    if (!placeholder) {
      console.warn("Placeholder not found");
      return;
    }

    // Create a document fragment to hold the independent divs
    const fragment = document.createDocumentFragment();

    // Iterate over all divs within the article
    const allDivs = Array.from(article.querySelectorAll("div"));

    allDivs.forEach((div) => {
      // Skip divs that are inside a table structure
      if (div.closest("table, thead, tbody, tfoot, tr, td, th")) {
        return;
      }

      // Remove the div from its current parent
      if (div.parentNode !== article) {
        div.parentNode.removeChild(div);
      }

      // Move any nested divs to be independent
      Array.from(div.querySelectorAll("div")).forEach((nestedDiv) => {
        if (!nestedDiv.closest("table, thead, tbody, tfoot, tr, td, th")) {
          fragment.appendChild(nestedDiv);
        }
      });

      // Append the div directly to the fragment, making it independent
      fragment.appendChild(div);
    });

    // Remove the placeholder from the DOM
    placeholder.remove();

    // Append the independent divs to the article
    article.appendChild(fragment);
  }

  async function handlePaste(e) {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    let projectId = props.projectInfo.id; // Reference to your project ID.
    let projectName = props.projectInfo.title; // Reference to your project name.
    const uploadPromises = [];

    // Initialize an empty document to build upon.
    let doc = document.implementation.createHTMLDocument("");

    // Extract HTML content if available.
    const pastedHTML = clipboardData.getData("text/html");
    if (pastedHTML) {
      const parser = new DOMParser();
      const parsedDoc = parser.parseFromString(pastedHTML, "text/html");

      // Handle embedded images by uploading them or leaving them as is.
      parsedDoc.querySelectorAll("img").forEach((img) => {
        const src = img.getAttribute("src");
        if (src.startsWith("data:image")) {
          // Base64 encoded image
          const uploadPromise = uploadBase64Image(src, projectName, projectId)
            .then((uploadedImageHTML) => uploadedImageHTML)
            .catch((error) => {
              console.error("Failed to upload base64 image:", error);
              return "";
            });
          uploadPromises.push(uploadPromise);
        } else {
          // This leaves URL-based images as is. You could extend this to handle these images differently.
          doc.body.appendChild(img.cloneNode(true));
        }
      });

      // Process the parsed document
      processDocument(parsedDoc);

      // Convert the processed document body into string and set it to doc body
      doc.body.innerHTML = parsedDoc.body.innerHTML;
    } else {
      // Fallback to plain text if HTML content is not available.
      const plainText = clipboardData.getData("text/plain");
      let textNode = document.createTextNode(plainText);
      let plainDiv = doc.createElement("div").appendChild(textNode);
      doc.body.appendChild(plainDiv);
    }

    // Wait for all base64 images to be uploaded and replace their src with the uploaded image URL.
    await Promise.all(uploadPromises).then((uploadedImagesHTML) => {
      uploadedImagesHTML.forEach((imgHTML) => {
        if (imgHTML) {
          doc.body.insertAdjacentHTML("beforeend", imgHTML);
        }
      });
    });

    // Here you can add custom logic to wrap images and tables if needed.
    await wrapImages(doc);
    wrapTables(doc, props);

    // Convert the document's body back to a string to insert into the editor.
    const finalHTML = doc.body.innerHTML;
    insertFinalHTML(finalHTML);

    props.orderByReference();
    props.onOrderByFigures();
    props.onOrderByTables();
    handleOnSaveClick();

    // Extract and move the divs after processing

    flattenDivs();

    // extractFollowingDivs();
  }
  // Utility function to upload a base64 image.
  async function uploadBase64Image(base64Data, projectName, projectId) {
    const mimeType = base64Data.match(/:(.*?);/)[1];
    const data = base64Data.split(",")[1];
    const blob = base64ToBlob(data, mimeType);
    const extension = mimeType.split("/")[1];
    const filename = `${projectName}_${Date.now()}.${extension}`;
    const file = new File([blob], filename, { type: mimeType });

    return await uploadProjectFileCopy(projectId, file, filename);
  }

  // Converts a base64 string to a Blob object.
  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length)
        .fill(0)
        .map((_, i) => slice.charCodeAt(i));
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: mimeType });
  }

  // Example function to insert the final HTML into the editor.
  function insertFinalHTML(html) {
    // Use your preferred method to insert HTML into your content editor.
    // This is a placeholder for where you would insert the HTML into the page or editor.
    if (document.queryCommandSupported("insertHTML")) {
      document.execCommand("insertHTML", false, html);
    } else {
      console.warn("Fallback insertion method needed");
      // Implement a fallback if necessary.
    }
  }

  async function wrapImages(doc) {
    const images = doc.querySelectorAll("img");
    images.forEach((img, index) => {
      // Create the custom structure elements
      const div = document.createElement("div");
      div.setAttribute("caption", "true");
      div.style.textAlign = "center";

      // Apply the max-width style to the image
      const styledImg = img.cloneNode(true);
      styledImg.style.maxWidth = "75%";

      const imgBlock = document.createElement("imgblock");
      imgBlock.appendChild(styledImg); // Use styledImg here

      // Create imgFigure and imgCaption
      const imgFigure = document.createElement("imgfigure");
      imgFigure.textContent = `Figure-${index + 1} `; // Dynamic numbering

      const imgCaption = document.createElement("imgcaption");
      imgCaption.textContent = "Image Caption";

      // Append everything to div
      div.appendChild(imgBlock);
      div.appendChild(imgFigure);
      div.appendChild(imgCaption);

      // Replace the original img with the wrapped version
      img.parentNode.replaceChild(div, img);
    });
  }

  function wrapTables(doc, props) {
    const tables = doc.querySelectorAll("table");
    const countExistingTables = () => {
      // Assuming you have a reference to your editor's content container, e.g., editorRef
      // Adjust this to match how you access your editor's content
      const editorContent =
        document.getElementById("editor") || editorRef.current;
      const existingTables = editorContent.querySelectorAll(
        '[tablecaption="true"]'
      );
      return existingTables.length;
    };
    let tableCount = countExistingTables();
    tables.forEach((table) => {
      // Increment tableCount to get the current table number
      tableCount++;

      // Create the custom structure elements
      const div = document.createElement("div");
      div.setAttribute("tablecaption", "true");
      div.style.textAlign = "center";

      const tableBlock = document.createElement("tableblock");

      // Prepare the table element
      table.removeAttribute("style");
      table.setAttribute("textwidth", "1");
      table.style.maxWidth = "100%";

      // Move the table into tableBlock
      tableBlock.appendChild(table.cloneNode(true));

      // Create tableNumber and tableCaption using createElement
      const tableNumber = document.createElement("tablenumber");
      tableNumber.textContent = `Table-${tableCount} `;

      const tableCaption = document.createElement("tablecaption");
      tableCaption.textContent = "Table Caption";

      // Append everything to div
      div.appendChild(tableBlock);
      div.appendChild(tableNumber);
      div.appendChild(tableCaption);

      // Replace the original table with the wrapped version
      table.parentNode.replaceChild(div, table);
    });
  }

  const GetNewId = () => {
    let guid = "libo" + CreateGuid();
    while ([...editorRef.current?.querySelectorAll(`#${guid}`)].length > 0) {
      guid = "libo" + CreateGuid();
    }
    return guid;
  };
  const handleClickOpenImageModel = () => {
    if (props.editImageElement && props.editImageElement != null) {
      let elBlock = props.editImageElement.parentNode;
      let imgdiv = elBlock.parentNode;
      let caption = imgdiv.getElementsByTagName("imgcaption");
      if (caption.length > 0) {
        caption = caption[0];
        setImageEditProperties({
          caption: caption.innerText,
          label: props.editImageElement.getAttribute("title"),
          size: props.editImageElement.style.maxWidth,
        });
      } else {
        setImageEditProperties({
          caption: "",
          label: props.editImageElement.getAttribute("title"),
          size: props.editImageElement.style.maxWidth,
        });
      }
    }
    setIsEditImageModelOpen(true);
  };

  const handleCloseImageModel = () => {
    setIsEditImageModelOpen(false);
    setImageEditProperties({ caption: "", label: "", size: "" });
    props.setEditImageElement(null);
  };

  const handleParageraphDragStart = (e) => {
    SetIscontentEditable(false);
    let node = e.target;
    while (node.tagName != "DIV") {
      node = node.parentNode;
    }
    node.style.opacity = "0.4";

    let list = dragElement;
    list.pop();
    list.push(node);
    setDragElement(list);

    e.effectAllowed = "move";
    //e.dataTransfer.setData('text', node.getAttribute("lineIndex") + '_' + node.id);
  };
  const handleDragEnd = (e) => {
    SetIscontentEditable(true);
    let node = e.target;
    while (node.tagName != "DIV") {
      node = node.parentNode;
    }
    node.style.opacity = "";

    let list = dragElement;
    list.pop();
    setDragElement(list);
    intervalFunction();
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    if (dragElement && dragElement.length == 1) {
      let node = e.target;
      while (
        node != null &&
        node.tagName != "ARTICLE" &&
        node.tagName != "DIV"
      ) {
        node = node.parentNode;
      }
      if (node == null || node.tagName == "ARTICLE") {
        return false;
      }
      node.style.opacity = "0.3";
    }
    e.preventDefault();
    return false;
  };

  let paragraphMouseClick = null;
  const handleMouseDownFordDragAndDrop = function (e) {
    const target = e.currentTarget || e.target;
    const after = getComputedStyle(target, ":after");
    let allowSelect = true;
    if (after) {
      // Then we parse out the dimensions
      const atop = Number(after.getPropertyValue("top").slice(0, -2));
      const aheight = Number(after.getPropertyValue("height").slice(0, -2));
      const aleft = Number(after.getPropertyValue("left").slice(0, -2));
      const awidth = Number(after.getPropertyValue("width").slice(0, -2));
      // And get the mouse position
      const ex = e.layerX;
      const ey = e.layerY;
      // Finally we do a bounds check (Is the mouse inside of the after element)
      if (
        ex > aleft &&
        ex < aleft + awidth &&
        ey > atop &&
        ey < atop + aheight
      ) {
        allowSelect = false;
        paragraphMouseClick = null;
      }
    }
    if (allowSelect) {
      paragraphMouseClick = this;
      paragraphMouseClick.removeAttribute("draggable");
      paragraphMouseClick.ondragstart = null;
      paragraphMouseClick.ondragend = null;
      paragraphMouseClick.ondragover = null;
      paragraphMouseClick.ondrop = null;
      paragraphMouseClick.ondragleave = null;
    }
  };
  const handleMouseUpFordDragAndDrop = function (e) {
    if (paragraphMouseClick != null) {
      paragraphMouseClick.setAttribute("draggable", "true");
      paragraphMouseClick.ondragstart = handleParageraphDragStart;
      paragraphMouseClick.ondragend = handleDragEnd;
      paragraphMouseClick.ondragover = handleDragOver;
      paragraphMouseClick.ondrop = handleDrop;
      paragraphMouseClick.ondragleave = handleDragLeave;
      paragraphMouseClick = null;
    }
  };
  const handleMouseLeaveFordDragAndDrop = function (e) {
    if (paragraphMouseClick != null) {
      paragraphMouseClick.setAttribute("draggable", "true");
      paragraphMouseClick.ondragstart = handleParageraphDragStart;
      paragraphMouseClick.ondragend = handleDragEnd;
      paragraphMouseClick.ondragover = handleDragOver;
      paragraphMouseClick.ondrop = handleDrop;
      paragraphMouseClick.ondragleave = handleDragLeave;
      paragraphMouseClick = null;
    }
  };
  const handleDragLeave = (e) => {
    if (dragElement && dragElement.length == 1) {
      let node = e.target;
      while (
        node != null &&
        node.tagName != "ARTICLE" &&
        node.tagName != "DIV"
      ) {
        node = node.parentNode;
      }
      if (node == null || node.tagName == "ARTICLE") {
        return false;
      }
      node.style.opacity = "";
    }
    e.preventDefault();
    return false;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (dragElement && dragElement.length == 1) {
      let node = e.target;
      node.style.opacity = "";
      try {
        while (
          node != null &&
          node.tagName != "ARTICLE" &&
          node.tagName != "DIV"
        ) {
          node = node.parentNode;
        }
        if (
          node == null ||
          node.tagName == "ARTICLE" ||
          dragElement[0] == null ||
          dragElement[0] == undefined ||
          dragElement[0] == NaN
        ) {
          return false;
        }
        let editor = editorRef.current;

        let DragEl = dragElement[0];
        let indexDragEl = Number(dragElement[0].getAttribute("lineIndex"));
        let indexDropEl = Number(node.getAttribute("lineIndex"));
        node.style.opacity = "";
        try {
          if (indexDropEl > indexDragEl) {
            node.after(DragEl);
          } else {
            editor.insertBefore(DragEl, node);
          }
        } catch (ex) {
          console.log(ex);
        }
      } catch {}
    }
    return false;
  };

  const intervalFunction = (changeOnEditor = false) => {
    try {
      const paragraphs = editorRef.current?.getElementsByTagName("div");
      let index = 0;
      for (const p of paragraphs) {
        const id = p.getAttribute("id");
        const hashid = p.getAttribute("hashid");
        index++;
        p.setAttribute("lineIndex", index);
        p.setAttribute("draggable", "true");
        p.ondragstart = handleParageraphDragStart;
        p.ondragend = handleDragEnd;
        p.ondragover = handleDragOver;
        p.ondrop = handleDrop;
        p.ondragleave = handleDragLeave;
        p.onmousedown = handleMouseDownFordDragAndDrop;
        p.onmouseup = handleMouseUpFordDragAndDrop;
        p.onmouseleave = handleMouseLeaveFordDragAndDrop;
        if (p.innerText.trim() == "") {
          p.setAttribute("placeholder", "true");
        } else {
          p.removeAttribute("placeholder");
        }
        if (id == null || id == "" || id == undefined || id == NaN) {
          let newId = GetNewId();

          p.setAttribute("id", newId);
          id = newId;
          if (
            selectdiv != null &&
            (selectdiv.id == null ||
              selectdiv.id == undefined ||
              selectdiv.id == NaN ||
              selectdiv.id == "")
          ) {
            selectdiv.setAttribute("id", id);
            onSelectDivChange(id, index);
            setSelectdiv(p);
          }
        }
        const newhashId =
          "a" + require("md5")(removeSuggestionsFromHTML(p.innerHTML));

        if (newhashId != hashid) {
          if (changeOnEditor) {
            p.removeAttribute("paraphrased");
          }
          if (selectdiv != null && selectdiv.id == id) {
            closeFloatSuggestion();
            selectdiv.setAttribute("hashid", newhashId);
            onSelectDivChange(id, index);
            p.setAttribute("hashid", newhashId);
            setSelectdiv(p);
          }

          p.setAttribute("hashid", newhashId);
          removeSuggestionOfElement(p);
        }
      }
    } catch {}
  };

  const closeCitattion = () => {
    if (citationProperty.ShowCitation) {
      let div = citationProperty.element;
      div.pop();
      citationProperty.element = div;
      setCitationProperty({
        ...citationProperty,
        element: div,
        ShowCitation: false,
      });
    }
  };
  const closePlagiarism = () => {
    if (PlagiarismProperty.ShowPlagiarism) {
      let div = PlagiarismProperty.element;
      div.pop();
      PlagiarismProperty.element = div;
      setPlagiarismProperty({
        ...PlagiarismProperty,
        element: div,
        ShowPlagiarism: false,
      });
    }
  };
  const closeFloatSuggestion = () => {
    closeCitattion();
    closePlagiarism();
  };

  useEffect(() => {
    interval = setInterval(() => {
      handleOnSaveClick();
    }, 60000);
    return () => {
      window.onresize = null;
      clearInterval(interval);
    };
  }, []);
  const removeFontOfContent = () => {
    let listFont = editorRef.current?.getElementsByTagName("FONT");
    if (listFont && listFont?.length > 0) {
      for (let font of listFont) {
        font.outerHTML = font.innerHTML;
      }
    }
  };
  const removeOldIdAndSetNewID = () => {
    let parageraph = editorRef.current?.getElementsByTagName("DIV");
    if (parageraph && parageraph?.length > 0) {
      for (let p of parageraph) {
        if (p.id == null || p.id == undefined || p.id == NaN || p.id == "") {
          p.setAttribute("id", GetNewId());
        } else if (!p.id.includes("libo")) {
          p.setAttribute("id", GetNewId());
        }
      }
    }
  };

  const setLiboMathClickingInLiboEditor = () => {
    let libomaths = editorRef.current?.getElementsByTagName("LIBOMATH");
    if (libomaths && libomaths?.length > 0) {
      for (let libomath of libomaths) {
        libomath.onclick = function () {
          setOpenFormula(Math.random(1000000));
        };
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      removeOldIdAndSetNewID();
      removeFontOfContent();
      setLiboMathClickingInLiboEditor();
      props.onOrderByFigures();
      props.onOrderByTables();

      handleResizeEditor(toolbarIsOpenDropDownPanel);
    }, 800);
    return () => {};
  }, [props.defaultValue]);

  useEffect(() => {
    setTimeout(() => {
      handleResizeEditor(toolbarIsOpenDropDownPanel);
    }, 1000);
  }, [props.isShowSettingPannel]);

  const handleResizeEditor = (isOpenToolbar) => {
    {
      try {
        let editor = editorRef.current;
        let toolbar = document.getElementById("editprtoolbar");
        let toolbar_offsetHeight = Number(toolbar.offsetHeight);

        editor.style.marginTop =
          (isOpenToolbar ? toolbar_offsetHeight : 0) + 30 + "px";

        editor.style.marginBottom = 30 + "px";

        editor.style.minHeight =
          "calc(100vh - " + (Number(editor.offsetTop) + 30) + "px)";
      } catch {}
    }
  };

  const handleCitationAcceptClick = (obj, citationIds) => {
    if (obj == null) {
      return false;
    }
    let Reference = null;
    let isCurentCurserPostionExistInElement = false;
    let selectCursorPosition =
      window.selectCursorPosition == undefined ||
      window.selectCursorPosition == null
        ? null
        : window.selectCursorPosition;
    if (selectCursorPosition == null) {
      toast.error("Please specify the location of the reference");
      return false;
    }

    if (selectCursorPosition != null) {
      let divElment = selectCursorPosition.element;

      if (divElment.tagName == "TABLENUMBER") {
        toast.error("Table Number " + "  is not citable ");
        return false;
      } else if (divElment.tagName == "IMGFIGURE") {
        toast.error("Figure Number " + "  is not citable ");
        return false;
      }
      while (
        divElment != null &&
        divElment.tagName != "DIV" &&
        divElment.tagName != "LIBOMATH" &&
        divElment.tagName != "ARTICLE"
      ) {
        divElment = divElment.parentNode;
      }
      if (divElment == null || divElment.tagName == "ARTICLE") {
        toast.error("Please specify the location of the reference");
        return false;
      }
      if (divElment.tagName == "LIBOMATH") {
        toast.error("Formula is not citable");
        return false;
      }
      if (
        divElment != null &&
        divElment.tagName == "DIV" &&
        divElment.hasAttribute("heading")
      ) {
        let nodeValue = divElment.getAttribute("heading");
        if (
          nodeValue != null &&
          nodeValue != undefined &&
          nodeValue != "" &&
          nodeValue != "div"
        ) {
          toast.error(
            headings.find((s) => s.value == nodeValue).title +
              "  is not citable "
          );
          return false;
        }
      }
      if (divElment != null && divElment.id == obj.id) {
        isCurentCurserPostionExistInElement = true;
        let refElment = selectCursorPosition.element;
        while (
          refElment != null &&
          refElment.tagName != "DIV" &&
          refElment.tagName != "REFERENCES" &&
          refElment.tagName != "ARTICLE"
        ) {
          refElment = refElment.parentNode;
        }
        if (refElment != null && refElment.tagName == "REFERENCES") {
          Reference = refElment;
        }
      }
    }

    let refInnerHTML = "";
    let count = 1;
    for (let citetion of citationIds) {
      refInnerHTML +=
        '<ref class="class_ref' +
        citetion.doc_id +
        '" title="' +
        citetion.citation_string +
        '" bibtex="' +
        citetion.bibtex +
        '" >1</ref>';
      if (count != citationIds.length) {
        refInnerHTML += ",";
      }
      count++;
    }

    if (obj != null) {
      if (isCurentCurserPostionExistInElement == false) {
        if (Reference == null) {
          let References = obj.getElementsByTagName("references");
          if (References != null && References.length != 0) {
            Reference = References[References.length - 1];
          }
        }
        if (Reference == null) {
          let ReferenceObj = document.createElement("references");

          if (refInnerHTML != "") {
            ReferenceObj.innerHTML = "[" + refInnerHTML + "]";
            let brElement = obj.getElementsByTagName("br");
            if (brElement != null && brElement.length != 0) {
              obj.insertBefore(ReferenceObj, brElement[brElement.length - 1]);
            } else {
              obj.appendChild(ReferenceObj);
            }
          }
        }
      } else {
        if (Reference == null) {
          let el = selectCursorPosition.element;
          let nextEl = selectCursorPosition.nextSibling;
          let startPart = selectCursorPosition.textContent.substring(
            0,
            selectCursorPosition.baseOffset
          );
          let endPart = selectCursorPosition.textContent.substring(
            selectCursorPosition.baseOffset
          );
          let part1 = document.createTextNode(startPart);
          let ReferenceObj = document.createElement("references");
          if (refInnerHTML != "") {
            ReferenceObj.innerHTML = "[" + refInnerHTML + "]";
            let part2 = document.createTextNode(endPart);

            if (nextEl != null) {
              el.insertBefore(part1, nextEl);
              el.insertBefore(ReferenceObj, nextEl);
              el.insertBefore(part2, nextEl);
            } else {
              el.appendChild(part1);
              el.appendChild(ReferenceObj);
              el.appendChild(part2);
            }
            part1.previousSibling.remove();
          }
        }
      }
      if (Reference != null) {
        if (refInnerHTML != "") {
          Reference.innerHTML = "[" + refInnerHTML + "]";
        } else {
          Reference.remove();
        }
      }
    }

    if (props.orderByReference != null) {
      props.orderByReference();
    }

    if (obj != null) {
      removeSuggestionOfElement(obj);
    }

    setTimeout(function () {
      handleOnSaveClick();
    }, 1000);
    return true;
  };

  const getTxtWithOutRefrence = (innerHTML) => {
    if (innerHTML == null || innerHTML == undefined || innerHTML == NaN) {
      return "";
    }
    let div = document.createElement("div");
    div.innerHTML = innerHTML;
    let refs = div.querySelectorAll("references");
    for (let ref of refs) ref.remove();

    return div.innerText.trim();
  };
  const handleBigPlagiarismSugClick = function (e) {
    closeCitattion();
    setAiOpenPanel(false);

    e.stopPropagation();
    editorOnclick(e, false);

    try {
      if (this.hasAttribute("data-plg")) {
        let dataPlg = JSON.parse(this.getAttribute("data-plg"));
        let div_id = this.id;
        let content = getTxtWithOutRefrence(this.innerHTML).trim();

        let div = PlagiarismProperty.element;
        if (div.length == 0 || div[0]?.id != div_id) {
          div.pop();
          div.push(this);
        }
        PlagiarismProperty.element = div;
        setPlagiarismProperty({
          ShowPlagiarism: true,
          dataPlg: dataPlg,
          element: div,
          content: content,
        });
      }
    } catch {}
  };

  const getRefrenceCitation = (target) => {
    let refIdList = [];
    if (target) {
      if (
        target.tagName == "REF" ||
        target.tagName == "REFERENCES" ||
        target.parentNode?.tagName == "REF" ||
        target.parentNode?.tagName == "REFERENCES"
      ) {
        let element = target;
        while (element != null && element.tagName != "REFERENCES") {
          element = element.parentNode;
        }

        if (element != null && element.tagName == "REFERENCES") {
          let refs = element.getElementsByTagName("ref");

          for (let ref of refs) {
            let refid = ref.className.replace("class_ref", "");
            if (refIdList.findIndex((s) => s.doc_id == refid) < 0) {
              let citation_string = ref.getAttribute("title");
              let bibtex = ref.getAttribute("bibtex");
              refIdList.push({
                doc_id: refid,
                citation_string: citation_string,
                bibtex: bibtex,
              });
            }
          }
        }
      }
    }
    return refIdList;
  };
  const BigSugOpen = (element, selectelement) => {
    removeAiAutoComplete();
    setAiOpenPanel(false);
    ClearAiAutocomplete();
    closePlagiarism();
    try {
      if (element.hasAttribute("data-sug")) {
        let dataSug = JSON.parse(element.getAttribute("data-sug"));
        let div_id = element.id;

        let refIdList = getRefrenceCitation(selectelement);
        let content = getTxtWithOutRefrence(element.innerHTML).trim();

        let divIdcitation = citationProperty.element;
        if (divIdcitation.length == 0 || divIdcitation[0]?.id != div_id) {
          divIdcitation.pop();
          divIdcitation.push(element);
        }
        citationProperty.element = divIdcitation;
        setCitationProperty({
          ShowCitation: true,
          dataSug: dataSug,
          element: divIdcitation,
          content: content,
          citationIds: refIdList,
        });
      }
      // let list = [];
      // createcitationSaggestion(this, list);
    } catch {}
  };
  const handleBigSugClick = function (e) {
    e.stopPropagation();
    editorOnclick(e, false);
    BigSugOpen(this, e.target);
    // const offset = getOffset(this);
  };
  const removeSuggestionOfElement = (element) => {
    let el = element;
    while (el != null && el.tagName != "ARTICLE") {
      if (el != null && el.tagName == "DIV") {
        el.classList.remove("sug");
        el.removeAttribute("data-sug");
        el.removeAttribute("class");
        el.style.backgroundColor = null;
        el.removeAttribute("data-plg");
        el.onclick = null;
      }
      el = el.parentNode;
    }
  };
  const removePlagiarismSuggestion = (element, e) => {
    let el = element;
    while (el != null && el.tagName != "DIV" && el.tagName != "ARTICLE") {
      el = el.parentNode;
    }
    if (el == null) {
      return;
    }
    if (element.hasAttribute("data-plg")) {
      removeSuggestionOfElement(element);
      closePlagiarism();
    }
    //intervalFunction();
  };
  function PosEnd(node, pos = 1) {
    try {
      var element = editorRef.current;
      var range = document.createRange();
      range.setStart(node, pos);
      var sel = window.getSelection();
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
    } catch {}
  }
  const removeImageBlock = (el) => {
    if (el && el != undefined && el != null) {
      if (el.tagName == "DIV" && el.hasAttribute("caption")) {
        let imgElements = el.getElementsByTagName("img");
        if (imgElements == null || imgElements.length == 0) {
          el.remove();
          props.onOrderByFigures();
          handleOnSaveClick();
        }
      } else if (
        el.parentNode?.tagName == "DIV" &&
        el.parentNode?.hasAttribute("caption")
      ) {
        let imgElements = el.parentNode.getElementsByTagName("img");
        if (imgElements == null || imgElements.length == 0) {
          el.parentNode.remove();
          props.onOrderByFigures();
          handleOnSaveClick();
        }
      } else if (
        el.parentNode?.parentNode?.tagName == "DIV" &&
        el.parentNode?.parentNode?.hasAttribute("caption")
      ) {
        let imgElements = el.parentNode.parentNode.getElementsByTagName("img");
        if (imgElements == null || imgElements.length == 0) {
          el.parentNode.parentNode.remove();
          props.onOrderByFigures();
          handleOnSaveClick();
        }
      }
    }
  };
  const removeTableBlock = (event, el) => {
    if (el && el != null && el != undefined) {
      if (el.tagName == "DIV" && el.hasAttribute("tablecaption")) {
        let tableElements = el.getElementsByTagName("table");
        if (tableElements == null || tableElements.length == 0) {
          el.remove();
          props.onOrderByTables();
          handleOnSaveClick();
        }
      } else if (
        el.parentNode?.tagName == "DIV" &&
        el.parentNode?.hasAttribute("tablecaption")
      ) {
        let tableElements = el.parentNode.getElementsByTagName("table");
        if (tableElements == null || tableElements.length == 0) {
          el.parentNode.remove();
          props.onOrderByTables();
          handleOnSaveClick();
        }
      } else if (
        el.parentNode?.parentNode?.tagName == "DIV" &&
        el.parentNode?.parentNode?.hasAttribute("tablecaption")
      ) {
        let tableElements =
          el.parentNode.parentNode.getElementsByTagName("table");
        if (tableElements == null || tableElements.length == 0) {
          el.parentNode.parentNode.remove();
          props.onOrderByTables();
          handleOnSaveClick();
        }
      }
    }
  };

  const cancelKeyDownEventOnReference = (e, element) => {
    //delete = 46 && backspace=8
    // left = 37 , right = 39 , up =38 , down = 40
    //27 = ESCAPE
    //tab = 9
    if (e != null && (e.which == 9 || e.which == 27)) {
      return true;
    }

    if (e.which == 37 || e.which == 39 || e.which == 38 || e.which == 40) {
      return false;
    } else {
      ClearAiAutocomplete();
    }

    let el = element;
    let shortCutKey = handleShortCutKey(e);

    if (shortCutKey != "") {
      if (shortCutKey == "Citations") {
        props.onCheckCitations();
      }
      return true;
    }
    if (el == null || el == undefined) {
      return false;
    }
    if (el != null && el.tagName == "LIBOMATH_SPACE") {
      //type first of formula

      if (e.key.toString().length == 1 && !e.ctrlKey) {
        var text = document.createElement("span");
        text.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
        el.parentNode.parentNode.insertBefore(text, el.parentNode);
        PosEnd(text);
        return true;
      }
      if (e.which == 13) {
        //click enter
        var text = document.createElement("span");
        text.innerHTML = "&nbsp;";
        el.parentNode.parentNode.insertBefore(text, el.parentNode);
        PosEnd(text);
        document.execCommand("formatBlock", false, "div");

        return true;
      }
    }
    if (el != null && el.tagName == "DIV" && el.hasAttribute("tablecaption")) {
      if (e.key.toString().length == 1 && !e.ctrlKey) {
        let caption = el.getElementsByTagName("tablecaption");
        if (caption.length > 0) {
          caption = caption[0];
          caption.innerHTML += e.which == 32 ? "&nbsp;" : e.key;
          PosEnd(caption);
        } else {
          var text = document.createElement("tablecaption");
          text.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
          el.appendChild(text);
          PosEnd(text);
        }
        handleOnSaveClick();
        return true;
      }
    }
    if (el != null && el.tagName == "TABLENUMBER") {
      if (e.key.toString().length == 1) {
        let caption = el.parentNode.getElementsByTagName("tablecaption");
        if (caption.length > 0) {
          caption = caption[0];
          caption.innerHTML += e.which == 32 ? "&nbsp;" : e.key;
          PosEnd(caption);
        } else {
          caption = document.createElement("tablecaption");
          caption.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
          el.parentNode.appendChild(caption);
          PosEnd(caption);
        }
        handleOnSaveClick();
        return true;
      }
    }

    if (el != null && el.tagName == "DIV" && el.hasAttribute("caption")) {
      if (e.key.toString().length == 1 && !e.ctrlKey) {
        let caption = el.getElementsByTagName("imgcaption");
        if (caption.length > 0) {
          caption = caption[0];
          caption.innerHTML += e.which == 32 ? "&nbsp;" : e.key;
          PosEnd(caption);
        } else {
          var text = document.createElement("imgcaption");
          text.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
          el.appendChild(text);
          PosEnd(text);
        }
        handleOnSaveClick();
        return true;
      }
    }
    if (el != null && el.tagName == "IMGFIGURE") {
      if (e.key.toString().length == 1) {
        let caption = el.parentNode.getElementsByTagName("imgcaption");
        if (caption.length > 0) {
          caption = caption[0];
          caption.innerHTML += e.which == 32 ? "&nbsp;" : e.key;
          PosEnd(caption);
        } else {
          caption = document.createElement("imgcaption");
          caption.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
          el.parentNode.appendChild(caption);
          PosEnd(caption);
        }

        handleOnSaveClick();
        return true;
      }
    }
    if (e.which == 8 || e.which == 46) {
      if (el != null && el.tagName == "IMGFIGURE") {
        return true;
      }
      if (el != null && el.tagName == "IMGCAPTION") {
        if (el.innerText.length == 1) {
          el.innerHTML = "&nbsp;";
          PosEnd(el);
          return true;
        }
      }
      if (el != null && el.tagName == "TABLENUMBER") {
        return true;
      }
      if (el != null && el.tagName == "TABLECAPTION") {
        if (el.innerText.length == 1) {
          el.innerHTML = "&nbsp;";
          PosEnd(el);
          return true;
        }
      }
    }

    if (
      !(
        e.which == 8 ||
        e.which == 46 ||
        e.which == 38 ||
        e.which == 40 ||
        e.which == 39 ||
        e.which == 37
      )
    ) {
      if (el != null && el.tagName == "REF") {
        return true;
      } else if (el != null && el.tagName == "REFERENCES") {
        if (e.key.toString().length == 1) {
          var text = document.createElement("span");
          text.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
          el.parentNode.insertBefore(text, el.nextSibling);
          PosEnd(text);
          handleOnSaveClick();
        } else if (e.which == 13) {
          let newDivElement = document.createElement("DIV");
          newDivElement.append(document.createElement("br"));
          while (el != null && el.tagName != "ARTICLE" && el.tagName != "DIV") {
            el = el.parentNode;
          }
          if (el.tagName == "DIV") {
            el.after(newDivElement);
            PosEnd(newDivElement);
          }
        }
        return true;
      } else if (e.which == 13) {
        while (
          el != null &&
          el.tagName != "ARTICLE" &&
          el.tagName != "TABLECAPTION" &&
          el.tagName != "TABLENUMBER" &&
          el.tagName != "IMGBLOCK" &&
          el.tagName == "LIBOMATH" &&
          el.tagName != "IMGFIGURE" &&
          el.tagName != "IMGCAPTION" &&
          el.tagName != "DIV"
        ) {
          el = el.parentNode;
        }
        if (
          el != null &&
          ((el.tagName == "DIV" && el.hasAttribute("caption")) ||
            el.tagName == "TABLECAPTION" ||
            el.tagName == "TABLENUMBER" ||
            el.tagName == "IMGBLOCK" ||
            el.tagName == "LIBOMATH" ||
            el.tagName == "IMGCAPTION" ||
            el.tagName == "IMGFIGURE")
        ) {
          let newDivElement = document.createElement("DIV");
          newDivElement.append(document.createElement("br"));
          if (el.tagName == "DIV") {
            el.after(newDivElement);
          } else {
            el.parentNode.after(newDivElement);
          }
          PosEnd(newDivElement);
          return true;
        }
      }
    }
    while (
      el != null &&
      el.tagName != "ARTICLE" &&
      el.tagName != "LIBOMATH" &&
      el.tagName != "DIV"
    ) {
      el = el.parentNode;
    }
    // left = 37 , right = 39 , up =38 , down = 40
    if (
      el.tagName == "LIBOMATH" &&
      !(e.which == 37 || e.which == 38 || (e.which == 39 && e.which == 40))
    ) {
      if (e.key.toString().length == 1) {
        var text = document.createElement("span");
        text.innerHTML = e.which == 32 ? "&nbsp;" : e.key;
        el.parentNode.insertBefore(text, el.nextSibling);
        PosEnd(text);
      }
      return true;
    }

    return false;
  };
  const allowToInsertFormula = (range) => {
    if (range == null) {
      return false;
    }
    let el = range.startContainer.parentNode;
    while (
      el != null &&
      el.tagName != "DIV" &&
      el.tagName != "REFERENCES" &&
      el.tagName != "ARTICLE"
    ) {
      el = el.parentNode;
    }
    if (el) {
      if (el.tagName == "DIV" && el.hasAttribute("caption")) {
        toast.error("Can not insert formula to the image block");
        return false;
      }
      if (range.startContainer.parentNode.tagName == "TABLENUMBER") {
        toast.error("Can not insert formula to the table numebr");
        return false;
      }
      if (el.tagName == "REFERENCES") {
        toast.error("Can not insert formula to the references block");
        return false;
      }
    }
    return true;
  };
  const allowToInsertTable = (range) => {
    if (range == null) {
      return false;
    }
    let el = range.startContainer.parentNode;
    while (
      el != null &&
      el.tagName != "DIV" &&
      el.tagName != "REFERENCES" &&
      el.tagName != "LIBOMATH" &&
      el.tagName != "ARTICLE"
    ) {
      el = el.parentNode;
    }
    if (el) {
      if (el.tagName == "DIV" && el.hasAttribute("caption")) {
        toast.error("Can not insert table to image block");
        return false;
      }
      if (el.tagName == "DIV" && el.hasAttribute("tablecaption")) {
        toast.error("Can not insert table to table block");
        return false;
      }
      if (el.tagName == "LIBOMATH") {
        toast.error("Can not insert table to formula");
        return false;
      }
      if (el.tagName == "REFERENCES") {
        toast.error("Can not insert table to references block");
        return false;
      }
    }
    return true;
  };
  const ClearAiAutocomplete = (except = null) => {
    let auto = autoComplete;
    let old = auto.pop();
    clearTimeout(old.intervalAiAutoComplete);
    let aiautocompletetags =
      editorRef.current?.getElementsByTagName("aiautocomplete");
    for (var item of aiautocompletetags) {
      try {
        if (item != except) {
          item.remove();
        }
      } catch {}
    }
    auto.push({
      intervalAiAutoComplete: null,
    });
    setAutoComplete(auto);
  };
  const setAiRefProperty = (element) => {
    if (element != null) {
      element.style.backgroundColor = "#f5eef1";
      element.setAttribute("class", "sug");
      element.onclick = handleAiPanelClick;
    }
    let el = aiElement;
    el.pop();
    el.push(element);
    setAiElement(el);
  };
  const handleAiPanelClick = function (e) {
    if (
      aiElement.length > 0 &&
      aiElement[0] != null &&
      aiElement[0].id == this.id &&
      aiElement[0].getAttribute("lineindex") == this.getAttribute("lineindex")
    ) {
      setAiOpenPanel(true);
    }
    e.stopPropagation();

    editorOnclick(e);
  };

  const getkeyWords = () => {
    let keyWordsDiv =
      editorRef.current?.querySelectorAll(`[heading='keywords']`);
    let keyWords = "";
    for (let el of keyWordsDiv) {
      keyWords += el.innerText.trim() + ",";
    }
    return keyWords;
  };
  const resetAIPannel = () => {
    RemoveAiPanel();
    ClearAiAutocomplete();
    let element = getAiElement();

    if (element == null) {
      RemoveAiPanel();
      ClearAiAutocomplete();
      removeAiAutoComplete();
    } else {
      setAiRefProperty(element);
      let Parageraph = getTxtWithOutRefrence(element.innerHTML).trim();
      setAiContent(Parageraph);
      setAiLoading(null);
      setAiOpenPanel(true);
      setAiResult(null);
      setAiTone(null);
      setAiCustomTone(null);
      removeAiAutoComplete();
    }
  };
  const RemoveAiPanel = () => {
    if (aiElement.length > 0 && aiElement[0] != null) {
      aiElement[0].style.backgroundColor = "";
      aiElement[0].removeAttribute("class");
      aiElement[0].onclick = null;
    }
    setAiContent("");
    setAiTitle("");
    setAiCaption("");
    setAiLoading(null);
    setAiOpenPanel(false);
    setAiResult(null);
    setAiTone(null);
    setAiCustomTone(null);
    setAiType(null);

    let el = aiElement;
    el.pop();
    setAiElement(el);
  };
  const removeAiAutoComplete = () => {
    try {
      if (autoComplete[0].intervalAiAutoComplete != null) {
        ClearAiAutocomplete();
      }

      setShowAiTone(false);
      clearTimeout(showAIInterval);
      setShowAIInterval(null);
      setShowPanelAi(false);
      setLoaderAiSuggestion(false);
      setShowAiSuggestion(false);
    } catch {}
  };
  const moveCursorToEnd = (contentEle) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(contentEle, contentEle.childNodes.length + 1);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  const replaceAiCompnentToNode = () => {
    setTimeout(() => {
      let prev = saveCursor();
      let compnents = editorRef.current?.getElementsByTagName("autocomplete");
      for (let item of compnents) {
        let tmp = document.createTextNode(item.innerHTML);
        item.replaceWith(tmp);
      }
      restoreCursor(prev);
    }, 100);
  };
  const saveAiComplete = () => {
    let aicompnent = editorRef.current?.getElementsByTagName("aiautocomplete");
    if (aicompnent && aicompnent.length > 0) {
      let nextel = aicompnent[0].nextSibling;

      if (nextel != null) {
        PosEnd(nextel, 0);
      } else {
        moveCursorToEnd(aicompnent[0]);
      }
      aicompnent[0].outerHTML =
        "<autocomplete>" +
        aicompnent[0].getAttribute("data-text") +
        "</autocomplete>";
    }
  };

  const checkAllowAiAutoComplete = (
    event = null,
    isCheckAiSuggestion = true
  ) => {
    if (
      window &&
      window.getSelection() &&
      window.getSelection().getRangeAt(0) &&
      window.getSelection().toString() == ""
    ) {
      let element = window.getSelection().getRangeAt(0)
        .startContainer.parentNode;
      let el = element;
      let elementArticle = element;
      let libomathElement = element;

      while (
        element != null &&
        element.tagName != "DIV" &&
        element.tagName != "ARTICLE"
      ) {
        element = element.parentNode;
      }

      while (elementArticle != null && elementArticle.tagName != "ARTICLE") {
        elementArticle = elementArticle.parentNode;
      }
      while (libomathElement != null && libomathElement.tagName != "LIBOMATH") {
        libomathElement = libomathElement.parentNode;
      }

      if (
        (event == null ||
          ((event.key.toString().length == 1 ||
            event.which == 32 || //space
            event.which == 38 || // arrow up
            event.which == 40 || // arrow down
            event.which == 39 || // arrow right
            event.which == 37 || // arrow left
            event.which == 8 || // backspace
            event.which == 9 || //tab
            event.which == 46) && // delete
            !event.ctrlKey &&
            !event.altKey &&
            !event.metaKey &&
            !event.shiftKey)) &&
        libomathElement == null &&
        elementArticle != null &&
        elementArticle.tagName == "ARTICLE" &&
        element != null &&
        element.tagName == "DIV" &&
        !element.hasAttribute("caption") &&
        !element.hasAttribute("tablecaption") &&
        !element.hasAttribute("data-sug") &&
        !element.hasAttribute("data-plg") &&
        !element.hasAttribute("heading") &&
        (isCheckAiSuggestion ||
          (el.tagName != "REFERENCES" &&
            el.tagName != "REF" &&
            el.parentNode?.tagName != "REFERENCES" &&
            el.parentNode?.tagName != "REF"))
      ) {
        if (
          el.tagName != "REFERENCES" &&
          el.tagName != "REF" &&
          el.parentNode?.tagName != "REFERENCES" &&
          el.parentNode?.tagName != "REF"
        ) {
          setDisabledAiAutoComplete(false);
        } else {
          setDisabledAiAutoComplete(true);
        }
        return true;
      }
    }
    setDisabledAiAutoComplete(true);
    return false;
  };
  const addAiAutoCompleteOnEditor = (txt, interval) => {
    try {
      let selectCursorPosition = window.selectCursorPosition;
      if (
        interval == autoComplete[0].intervalAiAutoComplete &&
        selectCursorPosition != null
      ) {
        restoreCursor(selectCursorPosition.prevCursor);

        let element = selectCursorPosition.element;
        let el = selectCursorPosition.element;
        let elementArticle = selectCursorPosition.element;
        while (
          element != null &&
          element.tagName != "DIV" &&
          element.tagName != "ARTICLE"
        ) {
          element = element.parentNode;
        }
        while (elementArticle != null && elementArticle.tagName != "ARTICLE") {
          elementArticle = elementArticle.parentNode;
        }

        if (checkAllowAiAutoComplete(null, false)) {
          let nextEl = selectCursorPosition.nextSibling;
          let startPart = selectCursorPosition.textContent.substring(
            0,
            selectCursorPosition.baseOffset
          );
          let endPart = selectCursorPosition.textContent.substring(
            selectCursorPosition.baseOffset
          );
          let part1 = document.createTextNode(startPart);
          let ReferenceObj = document.createElement("aiautocomplete");

          ReferenceObj.setAttribute("data-text", txt);
          let panelbox = document.createElement("span");
          panelbox.className = "panelbox";
          let saveBox = document.createElement("span");
          saveBox.className = "saveBox";
          saveBox.addEventListener("click", (e) => {
            saveAiComplete();
            replaceAiCompnentToNode();
          });
          let tabBox = document.createElement("span");
          tabBox.className = "tabBox";
          saveBox.append(tabBox);

          let DismissBox = document.createElement("span");
          DismissBox.className = "DismissBox";
          let EscBox = document.createElement("span");
          EscBox.className = "EscBox";
          DismissBox.addEventListener("click", (e) => {
            ClearAiAutocomplete();
          });
          DismissBox.append(EscBox);

          panelbox.append(saveBox);
          panelbox.append(DismissBox);
          ReferenceObj.append(panelbox);
          let part2 = document.createTextNode(endPart);

          if (nextEl != null) {
            el.insertBefore(part1, nextEl);
            el.insertBefore(ReferenceObj, nextEl);
            el.insertBefore(part2, nextEl);
          } else {
            el.appendChild(part1);
            el.appendChild(ReferenceObj);
            el.appendChild(part2);
          }
          ClearAiAutocomplete(ReferenceObj);
          PosEnd(part1, selectCursorPosition.baseOffset);
          part1.previousSibling.remove();
        } else {
          removeAiAutoComplete();
        }
      }
    } catch {}
  };
  const callAiAutoComplete = (Parageraph, timer) => {
    let intervalAiAutoComplete = setTimeout(() => {
      getAutoComplete(props.projectInfo?.id, { paragraph: Parageraph })
        .then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            let auto = autoComplete;
            if (res.data.completed_paragraph != null) {
              addAiAutoCompleteOnEditor(
                res.data.completed_paragraph,
                intervalAiAutoComplete
              );
              setAutoComplete(auto);
            } else toast.warn("AI Suggestion Is Empty");
            removeAiAutoComplete();
          }
        })
        .catch((err) => {
          removeAiAutoComplete();
          console.log(err);
          let auto = autoComplete;
          auto[0].AIresult = "";
          setAutoComplete(auto);

          toast.error(err.response.data.error);
        });
    }, timer);
    return intervalAiAutoComplete;
  };

  const setPosAiIcon = (panel = null) => {
    let pos = getCaretCoordinates();
    let left = pos.x - 30;
    let top = window.scrollY + pos.y - 85;

    let marginTop = Number(
      editorRef.current?.style.marginTop.replace("px", "")
    );
    let marginBot = Number(
      editorRef.current?.style.marginBottom.replace("px", "")
    );
    let showsubMenuLeft = true;

    if (panel == "aipanel") {
      let aiPanelLeft = editorRef.current?.offsetWidth - (left + 150);
      showsubMenuLeft = aiPanelLeft - 140 > 0;
      aiPanelLeft = aiPanelLeft > 0 ? left : left + aiPanelLeft;

      let aiPanelTop =
        editorRef.current?.offsetHeight + marginBot + marginTop - (top + 306);
      aiPanelTop = aiPanelTop > 0 ? top : top + aiPanelTop;
      top = aiPanelTop;
      left = aiPanelLeft;
    }
    //  else if (panel == "change_tone_panel") {
    //   let aiPanelLeft = editorRef.current?.offsetWidth - (left + 425);
    //   aiPanelLeft = aiPanelLeft > 0 ? left : left + aiPanelLeft;

    //   let aiPanelTop =
    //     editorRef.current?.offsetHeight + marginBot + marginTop - (top + 100);
    //   aiPanelTop = aiPanelTop > 0 ? top : top + aiPanelTop;
    //   top = aiPanelTop;
    //   left = aiPanelLeft;
    // }

    setShowAiSuggestion(true);
    setAiSuggestionPropperty({
      left: left + "px",
      top: top + "px",
      showsubMenuLeft: showsubMenuLeft,
    });
  };
  const showAiAutoComplete = (el, workwithtimer = false) => {
    removeAiAutoComplete();
    if (workwithtimer) {
      let prevCursor = saveCursor();
      let intervalid = setTimeout(() => {
        if (window.getSelection().getRangeAt(0) == prevCursor) {
          setShowAIInterval(null);
          setPosAiIcon();
        }
      }, 700);
      setShowAIInterval(intervalid); //intervalid;
    } else {
      setPosAiIcon();
    }
  };
  function getCaretCoordinates() {
    let x = 0,
      y = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      // Check if there is a selection (i.e. cursor in place)
      if (selection.rangeCount !== 0) {
        // Clone the range
        const range = selection.getRangeAt(0).cloneRange();
        // Collapse the range to the start, so there are not multiple chars selected
        range.collapse(true);
        // getCientRects returns all the positioning information we need
        const rect = range.getClientRects()[0];
        if (rect) {
          x = rect.left; // since the caret is only 1px wide, left == right
          y = rect.top; // top edge of the caret
        }
      }
    }
    return { x, y };
  }
  const setCurserPosition = () => {
    window.selectCursorPosition = {
      prevCursor: saveCursor(),
      element: window.getSelection().getRangeAt(0).startContainer.parentNode,
      extentOffset: window.getSelection().extentOffset,
      baseOffset: window.getSelection().baseOffset,
      textContent: window.getSelection().getRangeAt(0).startContainer
        .textContent,
      nextSibling: window.getSelection().getRangeAt(0).startContainer
        .nextSibling,
    };
  };

  const findDivElement = (element, e = null) => {
    let el = element;

    while (el != null && el.tagName != "DIV" && el.tagName != "ARTICLE") {
      el = el.parentNode;
    }

    setSelectdiv(el);
    try {
      if (e != null && e.which == 9) {
        // tab
        saveAiComplete();
        replaceAiCompnentToNode();
      } else if (
        e != null &&
        (e.which == 27 || //escape
          e.which == 13 || //enter
          e.which == 8 || //backspace
          e.which == 46 || //delete
          e.which == 32 || //space
          e.key.toString().length == 1)
      ) {
        ClearAiAutocomplete();
      }

      if (
        window &&
        window.getSelection() &&
        window.getSelection().getRangeAt(0)
      ) {
        if (checkAllowAiAutoComplete(e)) {
          showAiAutoComplete(element, e != null);
        } else {
          removeAiAutoComplete();
        }

        setCurserPosition();
        if (el != null && el.tagName == "DIV" && el.hasAttribute("caption")) {
          if (window.selectCursorPosition.element.tagName != "IMGCAPTION") {
            window.selectCursorPosition = null;
          }
        }
        if (
          el != null &&
          el.tagName == "DIV" &&
          el.hasAttribute("tablecaption")
        ) {
          if (window.selectCursorPosition.element.tagName == "TABLENUMBER") {
            window.selectCursorPosition = null;
          }
        }
      } else {
        window.selectCursorPosition = null;
      }
    } catch {
      window.selectCursorPosition = null;
    }

    onSelectDivChange(el?.id, el?.getAttribute("lineindex"));

    if (el?.id != citationProperty.element[0]?.id) {
      closeCitattion();
    }
    if (el?.id != PlagiarismProperty.element[0]?.id) {
      closePlagiarism();
    }

    try {
      props.onPositionImage(window.getSelection().getRangeAt(0));
    } catch {}
    let randomNumber = Math.random() * 100000;
    setRandomNumberForCloseFilter(randomNumber);
    intervalFunction(true);
    // setSelectdiv(el);
  };

  const PlagiarismSuggestion = () => {
    removeAiAutoComplete();
    RemoveAiPanel();
    ClearAiAutocomplete();
    closeFloatSuggestion();
    const newSugs = props.plagiarismSugs;
    // if (newSugs == null || newSugs.length == 0) {
    //     toast.success("doesn't have any plagiarism");
    // }
    for (const sug of newSugs) {
      const targets = [...document.querySelectorAll(`#${sug.id}`)];
      if (sug.suggestions == null || sug.suggestions.length == 0) {
        continue;
      }
      if (targets.length > 0) {
        for (const target of targets) {
          removeSuggestionOfElement(target);

          if (!isPlagiarismElement(target)) {
            continue;
          }
          let html = target.innerHTML.trim();
          html = html.replace(/(<div).*?(<\/div>)/g, html);
          var tempobj = document.createElement("DIV");
          tempobj.innerHTML = html;

          if (tempobj.innerText.trim() != "") {
            target.className = "sug";

            target.onclick = handleBigPlagiarismSugClick;
            target.setAttribute("data-plg", JSON.stringify(sug.suggestions));
            setColorOnPlagiarismElement(target, sug.suggestions);
          }
        }
      }
    }
  };
  const isPlagiarismElement = (target) => {
    let node = target;
    while (
      node != null &&
      node.tagName != "ARTICLE" &&
      !(node.tagName == "DIV" && node.parentNode?.tagName == "ARTICLE") &&
      !(
        node.hasAttribute("caption") ||
        node.hasAttribute("tablecaption") ||
        node.hasAttribute("heading")
      )
    ) {
      if (
        node != null &&
        ((node.hasAttribute("heading") &&
          node.getAttribute("heading") != "abstract" &&
          node.getAttribute("heading") != "div") ||
          node.hasAttribute("caption") ||
          node.hasAttribute("tablecaption"))
      ) {
        return false;
      }
      node = node.parentNode;
    }
    if (
      node != null &&
      ((node.hasAttribute("heading") &&
        node.getAttribute("heading") != "abstract" &&
        node.getAttribute("heading") != "div") ||
        node.hasAttribute("caption") ||
        node.hasAttribute("tablecaption"))
    ) {
      return false;
    }
    return true;
  };
  const setColorOnPlagiarismElement = (target, suggestions) => {
    let suggestionList = createPlagiarismSuggestionResult(suggestions);
    if (
      suggestionList.length > 0 &&
      props.plagiarismSuggestionContent != null
    ) {
      let suggestion_document = props.plagiarismSuggestionContent.find(
        (s) => s.doc_id == suggestionList[0].doc_id
      );
      if (suggestion_document != null) {
        target.style.backgroundColor = suggestion_document.color;
      }
    }
  };
  const suggestionLoop = () => {
    const newSugs = props.sugs;
    removeAiAutoComplete();
    RemoveAiPanel();
    ClearAiAutocomplete();
    closeFloatSuggestion();
    if (newSugs == null || newSugs.length == 0) {
      return;
    }
    // if (newSugs.suggestion == null || newSugs.suggestion.length == 0) {
    //   newSugs.suggestion = [];
    //   // toast.success('The paragraph does not have a citation')
    //   // return;
    // }
    const targets = [...document.querySelectorAll(`#${newSugs.id}`)];

    if (targets.length > 0) {
      for (const target of targets) {
        let iscontinue = false;
        removeSuggestionOfElement(target);

        if (target.getAttribute("lineindex") != newSugs.line) {
          continue;
        }
        let node = target;
        while (
          node != null &&
          node.tagName != "ARTICLE" &&
          !(node.tagName == "DIV" && node.parentNode?.tagName == "ARTICLE")
        ) {
          if (node != null && node.hasAttribute("heading")) {
            iscontinue = true;
            break;
          }
          node = node.parentNode;
        }
        if (
          node != null &&
          node.hasAttribute("heading") &&
          node.getAttribute("heading") != "div"
        ) {
          continue;
        }
        if (iscontinue) {
          continue;
        }
        let html = target.innerHTML.trim();
        html = html.replace(/(<div).*?(<\/div>)/g, html);
        var tempobj = document.createElement("DIV");
        tempobj.innerHTML = html;
        if (tempobj.innerText.trim() != "") {
          if (target.innerText.trim() != "") {
            target.className = "sug";
            target.onclick = handleBigSugClick;
            target.setAttribute("data-sug", JSON.stringify(newSugs.suggestion));
            if (
              (newSugs.id =
                target.id && newSugs.line == target.getAttribute("lineindex"))
            ) {
              BigSugOpen(target, window.selectCursorPosition?.element);
            }
          }
        }
      }
    }
    return;
  };

  useEffect(() => {
    if (props.editImageElement && props.editImageElement != null) {
      handleClickOpenImageModel();
    } else {
      handleCloseImageModel();
    }
  }, [props.editImageElement]);
  //! change interval to call just with onclick->getSuggestion
  useEffect(() => {
    intervalFunction();
    suggestionLoop();
  }, [sugs]);

  useEffect(() => {
    PlagiarismSuggestion();
  }, [plagiarismSugs]);

  useEffect(() => {}, [selChanged]);
  useEffect(() => {
    setTimeout(() => {
      handleResizeEditor(toolbarIsOpenDropDownPanel);
    }, 1000);
  }, [props.isShowSettingPannel]);

  const ResolveFormatHtmlDiv = (HTML = null) => {
    if (editorRef.current || HTML != null) {
      let divs = [];
      let body = null;
      if (HTML != null) {
        body = document.createElement("ARTICLE");
        body.innerHTML = HTML;
        divs = body.getElementsByTagName("div");
      } else {
        divs = editorRef.current.getElementsByTagName("div");
      }
      if (divs.length > 0) {
        for (let i = divs.length - 1; i >= 0; i--) {
          let div = divs[i];
          if (div.getElementsByTagName("BR").length == 0) {
            div.append(document.createElement("br"));
          }
          if (div.parentNode.tagName == "ARTICLE") {
            continue;
          }
          let parent = div;
          while (
            parent != null &&
            !(parent.tagName == "DIV" && parent.parentNode.tagName == "ARTICLE")
          ) {
            parent = parent.parentNode;
          }
          if (parent != null) {
            parent.after(div);
          }
        }
      }

      if (body != null) {
        return body.innerHTML;
      } else {
        return editorRef.current.innerHTML;
      }
    }
    return "";
  };
  const handleOnSaveClick = () => {
    intervalFunction();
    try {
      ResolveFormatHtmlDiv();
    } catch {}
    try {
      onChange(editorRef.current?.innerHTML);
    } catch {}
  };
  const handleChangeSelection = (e) => {
    setSelChanged(Math.random());
    setSel(window.getSelection());
  };
  const CheckTablesFormat = (objtables) => {
    for (let item of objtables) {
      let tablecaption = null;
      let tablenumber = null;
      let tablediv = item.parentNode?.parentNode;
      let tmp_div = null;
      let removeEl = [];
      let tableBlock = null;
      if (!item.hasAttribute("title")) {
        item.setAttribute("title", "");
      }
      if (
        item.style.maxWidth == null ||
        item.style.maxWidth == "" ||
        item.style.maxWidth == NaN ||
        item.style.maxWidth == undefined
      ) {
        item.style.maxWidth = "100%";
      }
      item.style.width = "";
      let textwidth = (
        Number(item.style.maxWidth.replace("%", "")) / 100
      ).toFixed(2);
      item.setAttribute("textwidth", textwidth);

      if (item.parentNode?.tagName == "TABLEBLOCK") {
        tableBlock = item.parentNode.cloneNode(true);
      } else {
        tableBlock = document.createElement("TABLEBLOCK");
        let item1 = item.cloneNode(true);
        tableBlock.append(item1);
      }

      if (item.parentNode?.nextElementSibling?.tagName == "TABLENUMBER") {
        removeEl.push(item.parentNode?.nextElementSibling);
        tablenumber = item.parentNode?.nextElementSibling.cloneNode(true);
      } else {
        tablenumber = document.createElement("TABLENUMBER");
        tablenumber.innerHTML = "Table-{number} ";
      }

      if (
        item.parentNode?.nextElementSibling?.nextElementSibling?.tagName ==
        "TABLECAPTION"
      ) {
        removeEl.push(item.parentNode?.nextElementSibling.nextElementSibling);
        tablecaption =
          item.parentNode?.nextElementSibling.nextElementSibling.cloneNode(
            true
          );
      } else {
        tablecaption = document.createElement("TABLECAPTION");
        tablecaption.innerHTML = " Table Caption";
      }

      if (
        tablediv &&
        tablediv.tagName == "DIV" &&
        tablediv.hasAttribute("tablecaption")
      ) {
        let needtoreplace = false;

        let tablenumbers = tablediv.getElementsByTagName("TABLENUMBER");
        let tablecaptions = tablediv.getElementsByTagName("TABLECAPTION");
        let tableBlocks = tablediv.getElementsByTagName("TABLEBLOCK");

        if (tableBlocks.length == 0) {
          needtoreplace = true;
          tablediv.append(tableBlock);
        }
        if (tablenumbers.length == 0) {
          needtoreplace = true;
          tablediv.append(tablenumber);
        }
        if (tablecaptions.length == 0) {
          needtoreplace = true;
          tablediv.append(tablecaption);
          tablediv.append(document.createElement("br"));
        }
        if (needtoreplace) {
          item.parentNode.parentNode.innerHTML = tablediv.innerHTML;
        }
      } else {
        tmp_div = document.createElement("div");
        tmp_div.style.textAlign = "center";
        tmp_div.setAttribute("tablecaption", "true");
        let table = item.cloneNode();
        table.innerHTML = item.innerHTML;
        tmp_div.append(tableBlock);
        tmp_div.append(tablenumber);
        tmp_div.append(tablecaption);
        tmp_div.append(document.createElement("br"));

        for (let removeitem of removeEl) {
          try {
            removeitem.remove();
          } catch {}
        }
        if (item.parentNode?.tagName == "TABLEBLOCK") {
          item.parentNode.replaceWith(tmp_div);
        } else {
          item.replaceWith(tmp_div);
        }
      }
    }
  };
  const editorOnclick = (e) => {
    findDivElement(e.target);
    if (e.target.tagName == "ARTICLE" && e.target.innerHTML == 0) {
      document.execCommand(
        "insertHtml",
        false,
        '<div heading="title" placeholder="true"><br></div>'
      );
    }
  };
  const getTextOfParageraphFromStartUntilClick = () => {
    restoreCursor(window.selectCursorPosition?.prevCursor);
    let select = window.getSelection();
    try {
      if (select && select != null) {
        var range = select.getRangeAt(0);
        // get text from first node position until click position
        var nodetxt = document.createTextNode(
          range.endContainer.textContent.substring(0, range.endOffset)
        );

        let tempdiv = document.createElement("div");
        let selectnode = range.endContainer;
        let temopnode = range.endContainer;
        //find parageraph
        while (
          temopnode != null &&
          temopnode.tagName != "DIV" &&
          temopnode.tagName != "ARTICLE"
        ) {
          temopnode = temopnode.parentNode;
        }

        // add all node parageraph to tempdiv before node select
        if (temopnode != null && temopnode.tagName == "DIV") {
          for (var node of temopnode.childNodes) {
            if (node != selectnode) {
              tempdiv.append(node.cloneNode(true));
            } else {
              break;
            }
          }

          tempdiv.append(nodetxt);
          return tempdiv.innerText;
        }
      }
    } catch (ex) {}
    return "";
  };
  const CheckImagesFormat = (objImgs) => {
    for (let item of objImgs) {
      let imgBlock = null;
      let imgcaption = null;
      let imgFigure = null;
      let imgdiv = item.parentNode?.parentNode;
      let tmp_div = null;
      let removeEl = [];
      if (!item.hasAttribute("title")) {
        item.setAttribute("title", "");
      }
      if (
        item.style.maxWidth == null ||
        item.style.maxWidth == "" ||
        item.style.maxWidth == NaN ||
        item.style.maxWidth == undefined
      ) {
        item.style.maxWidth = "50%";
      }

      let textwidth = (
        Number(item.style.maxWidth.replace("%", "")) / 100
      ).toFixed(2);
      item.setAttribute("scale", textwidth);
      item.setAttribute("loading", "lazy");

      if (item.parentNode?.tagName == "IMGBLOCK") {
        imgBlock = item.parentNode.cloneNode(true);
      } else {
        imgBlock = document.createElement("IMGBLOCK");
        let item1 = item.cloneNode(true);
        imgBlock.append(item1);
      }

      if (item.parentNode?.nextElementSibling?.tagName == "IMGFIGURE") {
        removeEl.push(item.parentNode.nextElementSibling);
        imgFigure = item.parentNode.nextElementSibling.cloneNode(true);
      } else {
        imgFigure = document.createElement("IMGFIGURE");
        imgFigure.innerHTML = "Figure-{number}";
        imgFigure.className = "Figure_0";
      }

      if (
        item.parentNode?.nextElementSibling?.nextElementSibling?.tagName ==
        "IMGCAPTION"
      ) {
        removeEl.push(item.parentNode.nextElementSibling.nextElementSibling);
        imgcaption =
          item.parentNode.nextElementSibling.nextElementSibling.cloneNode(true);
      } else {
        imgcaption = document.createElement("IMGCAPTION");
        imgcaption.innerHTML = "Image Caption";
      }

      if (imgdiv && imgdiv.tagName == "DIV" && imgdiv.hasAttribute("caption")) {
        let needtoreplace = false;
        let imgBlocks = imgdiv.getElementsByTagName("IMGBLOCK");
        let imgFigures = imgdiv.getElementsByTagName("IMGFIGURE");
        let imgcaptions = imgdiv.getElementsByTagName("IMGCAPTION");
        if (imgBlocks.length == 0) {
          needtoreplace = true;
          imgdiv.append(imgBlock);
        }
        if (imgFigures.length == 0) {
          needtoreplace = true;
          imgdiv.append(imgFigure);
        }
        if (imgcaptions.length == 0) {
          needtoreplace = true;
          imgdiv.append(imgcaption);
          imgdiv.append(document.createElement("br"));
        }
        if (needtoreplace) {
          item.parentNode.parentNode.innerHTML = imgdiv.innerHTML;
        }
      } else {
        tmp_div = document.createElement("div");
        tmp_div.style.textAlign = "center";
        tmp_div.setAttribute("caption", "true");
        tmp_div.append(imgBlock);
        tmp_div.append(imgFigure);
        tmp_div.append(imgcaption);
        tmp_div.append(document.createElement("br"));

        for (let removeitem of removeEl) {
          try {
            removeitem.remove();
          } catch {}
        }
        if (item.parentNode?.tagName == "IMGBLOCK") {
          item.parentNode.replaceWith(tmp_div);
        } else {
          item.replaceWith(tmp_div);
        }
      }
    }
  };
  const getAiElement = () => {
    restoreCursor(window.selectCursorPosition?.prevCursor);

    try {
      let element = window.selectCursorPosition.element;
      while (
        element != null &&
        element.tagName != "DIV" &&
        element.tagName != "ARTICLE"
      ) {
        element = element.parentNode;
      }
      // console.log(element);
      if (element != null && element.tagName == "DIV") {
        return element;
      }
    } catch (ex) {}
    return null;
  };

  const handleOnPasteForProccessImageAndTable = (value) => {
    let doc = document.createElement("div");
    doc.innerHTML = value;

    let objImgs = doc.getElementsByTagName("img");
    let objtables = doc.getElementsByTagName("table");
    CheckImagesFormat(objImgs);
    CheckTablesFormat(objtables);
    return doc.innerHTML;
  };

  return (
    <div
      className={
        classes.LiboEditor +
        " " +
        (props.isShowSettingPannel
          ? classes.showSettingOnEditor
          : classes.showEditorFullWidth)
      }
      style={
        {
          // overflowY: "auto",
          // backgroundColor: "#a5a4a4",
        }
      }
    >
      {isOpenHeadersPanel && (
        <div className={classes.headersPanel}>
          <HeaderPreview html={editorRef.current?.innerHTML} />
        </div>
      )}
      <Toolbar
        htmlContent={editorRef.current?.innerHTML}
        className={
          props.isShowSettingPannel
            ? classes.showSettingOnEditor
            : classes.showEditorFullWidth
        }
        cursorPostion={PosEnd}
        setLiboMathClickingInLiboEditor={setLiboMathClickingInLiboEditor}
        openFormula={openFormula}
        isShowDropDownPanel={true}
        isOpenDropDownPanel={toolbarIsOpenDropDownPanel}
        isShowHeadersItem={true}
        isOpenHeadersPanel={props.isOpenHeadersPanel}
        onClickHeadersPanel={props.onClickHeadersPanel}
        onClickDropDownPanel={() => {
          setToolbarIsOpenDropDownPanel(!toolbarIsOpenDropDownPanel);
        }}
        id="editprtoolbar"
        sel={sel}
        ExportLiboberryGenerator={props.ExportLiboberryGenerator}
        selChanged={selChanged}
        onAllowToInsertTable={allowToInsertTable}
        onAllowToInsertFormula={allowToInsertFormula}
        handleOnSaveClick={() => {
          handleOnSaveClick();
          toast.success("Document has been saved successfully!");
        }}
        onOrderByTables={() => {
          props.onOrderByTables();
          handleOnSaveClick();
        }}
        isShowSettingPannel={props.isShowSettingPannel}
        onSetIsShowSettingPannel={(value) => {
          props.onSetIsShowSettingPannel(value);
        }}
        onResizeEditor={(value) => {
          handleResizeEditor(toolbarIsOpenDropDownPanel);
        }}
        ShowSetting={false}
        style={{ position: "fixed" }}
        projectId={props.projectInfo?.id}
        allowToShare={props.projectInfo?.is_owner}
      />
      <article
        contentEditable={iscontentEditable}
        onPaste={handlePaste}
        onCopy={handleCopy}
        onKeyDown={(e) => {
          removeAiAutoComplete();

          if (
            cancelKeyDownEventOnReference(
              e,
              window.getSelection().getRangeAt(0).startContainer.parentNode
            )
          ) {
            e.preventDefault();
          }

          if (e.target.innerHTML.length === 0) {
            document.execCommand(
              "insertHtml",
              false,
              '<div heading="title" placeholder="true"><br></div>'
            );
          }

          handleChangeSelection();
        }}
        onClick={(e) => {
          editorOnclick(e);
        }}
        key={"editor"}
        id={"editor"}
        style={{
          minHeight: 150,
          outline: "none",
          backgroundColor: "white",

          // overflowY: "auto",
        }}
        ref={editorRef}
        className={classes.LiboEditorContent}
        onKeyUp={(e) => {
          if (
            pressEnterChange_tone == false &&
            e.which == 13 &&
            e.shiftKey == false &&
            window.getSelection().getRangeAt(0) != null
          ) {
            let el = window.getSelection().getRangeAt(0).startContainer;

            if (!el.tagName) {
              el = el.parentNode;
            }
            if (
              !(
                el == null ||
                el.tagName == "REF" ||
                el.tagName == "REFERENCES" ||
                el.parentNode?.tagName == "REF" ||
                el.parentNode?.tagName == "REFERENCES"
              )
            ) {
              while (
                el != null &&
                el.tagName != "DIV" &&
                el.tagName != "ARTICLE"
              ) {
                el = el.parentNode;
              }
              if (el != null && el.tagName == "DIV") {
                el.removeAttribute("heading");
                el.removeAttribute("caption");
                el.removeAttribute("style");
                el.removeAttribute("id");
                let listFont = el.getElementsByTagName("FONT");
                if (listFont.length > 0) {
                  for (let font of listFont) {
                    font.outerHTML = font.innerHTML;
                  }
                }
              }
            }
          }
          setPressEnterChange_tone(false);
          let el = window?.getSelection()?.getRangeAt(0);
          removeTableBlock(e, el?.startContainer);
          removeImageBlock(e, el?.startContainer);
          removePlagiarismSuggestion(el?.startContainer.parentNode, e);
          findDivElement(el?.startContainer.parentNode, e);

          handleChangeSelection();
        }}
        onMouseLeave={handleChangeSelection}
        onMouseDown={handleChangeSelection}
        onMouseUp={handleChangeSelection}
        dangerouslySetInnerHTML={{ __html: props.defaultValue }}
      >
        {/*<span class="sug">Hello</span> Text*/}
      </article>
      {showAiSuggestion && (
        <>
          <div
            onClick={() => {
              if (!showPanelAi) {
                handleOnSaveClick();
                restoreCursor(window.selectCursorPosition?.prevCursor);
                setPosAiIcon("aipanel");
                setShowPanelAi((showPanelAi) => true);
              }
            }}
            style={{
              left: aiSuggestionPropperty.left,
              top: aiSuggestionPropperty.top,
            }}
            title={"LiboBerry AI Assistant"}
            ref={aISuggestion}
            className={
              "aIPanelSuggestion" +
              (showPanelAi && !loaderAiSuggestion ? "_open" : "")
            }
          >
            {(!showPanelAi || loaderAiSuggestion) && (
              <div
                className={
                  "aISuggestion" + (loaderAiSuggestion ? " aILoader" : "")
                }
              >
                <AIIcon color={"#fbaf46"} />
              </div>
            )}

            {showPanelAi && !loaderAiSuggestion && (
              <div style={{ width: "180px" }}>
                <div onClick={() => {}} className={"aiPanelSuggestionHeader"}>
                  <div className={"aITextItem"}>
                    <AIIcon color={"#fbaf46"} marginRight={"5px"} /> LiboBerry
                  </div>
                </div>

                <div className={"aISuggestion_BoxItem"}>
                  <div
                    className={
                      "aISuggestion_Item" +
                      (disabledAiAutoComplete ? "_disabled" : "")
                    }
                    onClick={() => {
                      if (!disabledAiAutoComplete) {
                        restoreCursor(window.selectCursorPosition?.prevCursor);
                        setPosAiIcon();
                        ClearAiAutocomplete();
                        RemoveAiPanel();
                        setShowPanelAi(false);
                        setLoaderAiSuggestion(true);

                        let Parageraph =
                          getTextOfParageraphFromStartUntilClick();
                        let intervalid = callAiAutoComplete(Parageraph, 0);
                        let auto = autoComplete;
                        auto.pop();
                        auto.push({
                          intervalAiAutoComplete: intervalid,
                        });
                        setAutoComplete(auto);
                      }
                    }}
                  >
                    <div className={"aITextItem"}>
                      <BorderColorIcon style={{ width: "0.7em" }} />
                      &nbsp; Continue writing...
                    </div>
                    <div className={"aiSuggestionEnter_Icon"}>
                      <EnterIcon />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      restoreCursor(window.selectCursorPosition?.prevCursor);
                      setPosAiIcon();
                      ClearAiAutocomplete();
                      RemoveAiPanel();
                      setShowPanelAi(false);
                      setLoaderAiSuggestion(true);
                      props.onCheckCitations();
                    }}
                    className={"aISuggestion_Item"}
                  >
                    <div className={"aITextItem"}>
                      <CallSplitIcon style={{ width: "0.8em" }} /> &nbsp;Insert
                      citation
                    </div>
                    <div className={"aiSuggestionEnter_Icon"}>
                      <EnterIcon />
                    </div>
                  </div>
                  <div
                    className={"aISuggestion_Item"}
                    onClick={() => {
                      resetAIPannel();
                      setAiTitle("AI Paraphrase");
                      setAiCaption("Paraphrased");
                      setAiType("paraphrase");
                    }}
                  >
                    <div className={"aITextItem"}>
                      <FindReplaceIcon style={{ width: "0.8em" }} />
                      &nbsp; Paraphrase
                    </div>
                    <div className={"aiSuggestionEnter_Icon"}>
                      <EnterIcon />
                    </div>
                  </div>
                  {/* <div className={"aISuggestion_Item_disabled"}>
                  <FingerprintIcon /> &nbsp;Check Plagiarism
                </div> */}

                  <div
                    className={"aISuggestion_Item"}
                    onClick={() => {
                      resetAIPannel();
                      setAiTitle("AI Make longer");
                      setAiCaption("Make longer");
                      setAiType("extend");
                    }}
                  >
                    <div className={"aITextItem"}>
                      <ReorderIcon style={{ width: "0.8em" }} /> &nbsp;Make
                      longer
                    </div>
                    <div className={"aiSuggestionEnter_Icon"}>
                      <EnterIcon />
                    </div>
                  </div>
                  <div
                    className={"aISuggestion_Item"}
                    onClick={() => {
                      resetAIPannel();
                      setAiTitle("AI Summarize");
                      setAiCaption("Summarized");
                      setAiType("summarize");
                    }}
                  >
                    <div className={"aITextItem"}>
                      <NotesIcon style={{ width: "0.8em" }} /> &nbsp;Summarize
                    </div>
                    <div className={"aiSuggestionEnter_Icon"}>
                      <EnterIcon />
                    </div>
                  </div>
                  <div
                    className={"aISuggestion_Item"}
                    onClick={() => {
                      resetAIPannel();
                      setAiTitle("AI Make shorter");
                      setAiCaption("Make shorter");
                      setAiType("shorten");
                    }}
                  >
                    <div className={"aITextItem"}>
                      <DragHandleIcon style={{ width: "0.8em" }} /> &nbsp;Make
                      shorter
                    </div>
                    <div className={"aiSuggestionEnter_Icon"}>
                      <EnterIcon />
                    </div>
                  </div>
                  <div className={"aISuggestion_MenuItem"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      onClick={() => {
                        restoreCursor(window.selectCursorPosition?.prevCursor);
                        // setPosAiIcon("change_tone_panel");
                        setShowAiTone(!showAiTone);
                      }}
                    >
                      <div className={"aITextItem"}>
                        <BarChartIcon style={{ width: "0.8em" }} /> &nbsp;Change
                        tone
                      </div>
                      <div className={"aiSuggestionArrow_Icon"}>
                        <ArrowRightIcon />
                      </div>
                    </div>
                    <>
                      <div
                        className={"aISuggestion_BoxItem aiSubMenuOpen"}
                        style={{
                          display: showAiTone ? "block" : "none",
                          width: "170px",
                          left: aiSuggestionPropperty.showsubMenuLeft
                            ? "170px"
                            : "-160px",
                        }}
                      >
                        <div>
                          {AiTones.map((tone, index) => {
                            return (
                              <div
                                key={index}
                                title={tone.label + " Tone"}
                                style={{ fontSize: "1em" }}
                                className={"aISuggestion_Item"}
                                onClick={() => {
                                  resetAIPannel();
                                  setAiTitle("AI Change tone : " + tone.label);
                                  setAiCaption("Change tone");
                                  setAiType("change_tone");
                                  setAiTone(tone.key);
                                }}
                              >
                                <div className={"aITextItem"}>{tone.label}</div>
                                <div className={"aiSuggestionEnter_Icon"}>
                                  <EnterIcon />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            padding: "10px 0px 0px 0px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            placeholder="Custom Tone"
                            variant="outlined"
                            size="small"
                            disabled={false}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (
                                        aiCustomTone != null &&
                                        aiCustomTone.trim() != ""
                                      ) {
                                        let temp = aiCustomTone;
                                        resetAIPannel();
                                        setAiTitle(
                                          "AI Change Tone : " +
                                            temp.substring(0, 30) +
                                            (temp.length > 30 ? "..." : "")
                                        );
                                        setAiCaption("Change Tone");
                                        setAiType("change_tone");
                                        setAiTone(temp);
                                      }
                                    }}
                                  >
                                    <EnterIcon title={"Enter"} />
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            defaultValue={aiCustomTone}
                            onKeyDown={(e) => {
                              if (
                                e.which == 13 &&
                                aiCustomTone != null &&
                                aiCustomTone.trim() != ""
                              ) {
                                setPressEnterChange_tone(true);
                                e.preventDefault();

                                let temp = aiCustomTone;
                                resetAIPannel();
                                setAiTitle(
                                  "AI Change Tone : " +
                                    temp.substring(0, 30) +
                                    (temp.length > 30 ? "..." : "")
                                );
                                setAiCaption("Change Tone");
                                setAiType("change_tone");
                                setAiTone(temp);
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.which == 13) e.preventDefault();
                            }}
                            onChange={(e) => {
                              setAiCustomTone(e.target.value);
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </>
                  </div>
                  {/* <div
                  className={"aISuggestion_Item"}
                  onClick={() => {
                    resetAIPannel();
                    setAiTitle("AI Simplify");
                    setAiCaption("Simplify");
                    setAiType("simplify");
                  }}
                >
                  <div className={"aITextItem"}>
                    <AIIcon /> &nbsp;Simplify
                  </div>
                  <div className={"aiSuggestionEnter_Icon"}>
                    <EnterIcon />
                  </div>
                </div> */}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {aiOpenPanel && (
        <PopUpIconButton
          showPopup={aiOpenPanel}
          onSetShowPopup={setAiOpenPanel}
          showbtnPopup={false}
          customValue={
            <AiTyping
              Caption={aiCaption}
              status={aiLoading}
              content={aiContent}
              onStatus={setAiLoading}
              onResult={setAiResult}
              Tone={aiTone}
              result={aiResult}
              type={aiType}
              ProjectId={props.projectInfo?.id}
            />
          }
          footerValue={
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingTop: "20px",
              }}
            >
              {aiLoading == "success" && aiResult != null && aiResult != "" && (
                <>
                  <div>
                    <input
                      type="button"
                      value={"Replace"}
                      className={"liboBtnApprove"}
                      onClick={() => {
                        if (aiElement.length > 0 && aiElement[0]) {
                          aiElement[0].innerHTML = aiResult;
                        }
                        RemoveAiPanel();
                      }}
                    ></input>
                  </div>
                </>
              )}
              {((aiLoading == "success" &&
                aiResult != null &&
                aiResult != "") ||
                aiLoading == "error") && (
                <>
                  <div>
                    <a
                      className={"liboBtnRefreshWithIcon"}
                      onClick={() => {
                        setAiLoading(
                          aiLoading == "error" ? "retry" : "regenerate"
                        );
                      }}
                    >
                      <>
                        {aiLoading == "error" && (
                          <>
                            Try Again{" "}
                            <RefreshIcon
                              style={{ width: "0.8em" }}
                              className={"rotate360"}
                            />
                          </>
                        )}
                        {aiLoading == "success" && (
                          <>
                            Regenerate{" "}
                            <AutorenewIcon
                              style={{ width: "0.8em", marginLeft: "3px" }}
                              className={"rotate360"}
                            />
                          </>
                        )}
                        &nbsp;
                      </>
                    </a>
                  </div>
                </>
              )}
              {aiLoading != null && (
                <div>
                  <input
                    type="button"
                    value={"Dismiss"}
                    className={"liboBtnCancel"}
                    onClick={() => {
                      RemoveAiPanel();
                    }}
                  ></input>
                </div>
              )}
            </div>
          }
          title={aiTitle}
          pBottom="55px"
          pRight="0px"
          bBottom="0px"
          bRight="0px"
          bWidth="25px"
        />
      )}
      {citationProperty.ShowCitation && (
        <Citation
          bindDocumentCitations={props.bindDocumentCitations}
          onCloseFloatSuggestion={() => {
            closeFloatSuggestion();
          }}
          keyWords={getkeyWords()}
          closeFilter={randomNumberForCloseFilter}
          Element={citationProperty.element[0]}
          dataSug={citationProperty.dataSug}
          documentRefrence={props.documentCitation}
          onDocumentRefrence={props.setDocumentCitation}
          ProjectId={props.projectInfo?.id}
          citationIds={citationProperty.citationIds}
          onCitationAcceptClick={(element, citationIds) => {
            return handleCitationAcceptClick(element, citationIds);
          }}
          onDismissClick={(element) => {
            if (element != null && element) {
              removeSuggestionOfElement(element);
            }

            closeFloatSuggestion();
          }}
          content={citationProperty.content}
        />
      )}
      {PlagiarismProperty.ShowPlagiarism && (
        <Plagiarism
          onCloseFloatSuggestion={() => {
            closeFloatSuggestion();
          }}
          Element={PlagiarismProperty.element[0]}
          dataPlg={PlagiarismProperty.dataPlg}
          ProjectId={props.projectInfo?.id}
          onReplaceClick={(element, paraphraseResult) => {
            if (element && element != null) {
              element.innerHTML = paraphraseResult;
              element.setAttribute("paraphrased", "true");
            }
            if (element != null && element) {
              removeSuggestionOfElement(element);
            }
            if (props.orderByReference != null) {
              props.orderByReference();
            }
            if (props.onOrderByTables != null) {
              props.onOrderByTables();
            }
            if (props.onOrderByFigures != null) {
              props.onOrderByFigures();
            }
            if (
              props.onOrderByFigures != null ||
              props.onOrderByTables != null
            ) {
              handleOnSaveClick();
            }
          }}
          onDismissClick={(element) => {
            if (element != null && element) {
              removeSuggestionOfElement(element);
            }
            closeFloatSuggestion();
          }}
          content={PlagiarismProperty.content}
        />
      )}
      {isEditImageModelOpen && (
        <Dialog
          key={"EditImageDialog"}
          classes={{ paper: { background: "red" } }}
          open={isEditImageModelOpen}
          onClose={handleCloseImageModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{ color: "white", backgroundColor: "#eb3449" }}
            id="alert-dialog-title"
          >
            {"Edit Image"}
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              id="txtImageCaption"
              label="Image Caption"
              variant="outlined"
              style={{ marginTop: "30px" }}
              disabled={false}
              defaultValue={imageEditProperties.caption}
              onChange={(e) =>
                setImageEditProperties({
                  caption: e.target.value,
                  label: imageEditProperties.label,
                  size: imageEditProperties.size,
                })
              }
            />
            <TextField
              id="txtImageLabel"
              label="Image Label"
              variant="outlined"
              style={{ marginTop: "30px" }}
              disabled={false}
              defaultValue={imageEditProperties.label}
              onChange={(e) => {
                setImageEditProperties({
                  caption: imageEditProperties.caption,
                  label: e.target.value,
                  size: imageEditProperties.size,
                });
              }}
            />
            <br />
            <FormControl
              component="fieldset"
              style={{
                padding: "5px 0px 5px 8px",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "5px",
              }}
            >
              <FormLabel
                component="legend"
                style={{
                  fontSize: "0.75em",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Image Size
              </FormLabel>
              <RadioGroup
                aria-label="ddlImageSize"
                name="ddlImageSize"
                value={imageEditProperties.size}
                onChange={(e) => {
                  setImageEditProperties({
                    caption: imageEditProperties.caption,
                    label: imageEditProperties.label,
                    size: e.target.value,
                  });
                }}
              >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {ImageSizes.map((type, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        style={{ whiteSpace: "nowrap", fontSize: "0.9rem" }}
                        value={type.key}
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: "0.92rem" }}>
                            {type.label}
                          </span>
                        }
                      />
                    );
                  })}
                </div>
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModel} className={"liboBtnCancel"}>
              Cancel
            </Button>

            <Button
              onClick={() => {
                if (props.editImageElement && props.editImageElement != null) {
                  let elBlock = props.editImageElement.parentNode;
                  props.editImageElement.remove();
                  removeImageBlock(elBlock);
                }
                handleCloseImageModel();
              }}
              className={"liboBtnRemove"}
            >
              Remove
            </Button>

            <Button
              onClick={() => {
                if (props.editImageElement && props.editImageElement != null) {
                  props.editImageElement.style.maxWidth =
                    imageEditProperties.size;
                  let textwidth = (
                    Number(imageEditProperties.size.replace("%", "")) / 100
                  ).toFixed(2);
                  props.editImageElement.setAttribute("scale", textwidth);
                  props.editImageElement.setAttribute("loading", "lazy");
                  props.editImageElement.setAttribute(
                    "title",
                    imageEditProperties.label
                  );
                  let elBlock = props.editImageElement.parentNode;
                  let imgdiv = elBlock.parentNode;
                  let captions = imgdiv.getElementsByTagName("imgcaption");
                  if (captions.length > 0) {
                    let caption = captions[0];
                    caption.innerText = imageEditProperties.caption;
                  } else {
                    var text = document.createElement("imgcaption");
                    text.innerHTML = imageEditProperties.caption;
                    imgdiv.appendChild(text);
                  }
                  handleOnSaveClick();
                  PosEnd(imgdiv);
                }
                handleCloseImageModel();
              }}
              className={"liboBtnApprove"}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default LiboEditor;

LiboEditor.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onSelectDivChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  documentCitation: PropTypes.arrayOf(PropTypes.object),
  onPositionImage: PropTypes.func,
  onOrderByFigures: PropTypes.func,
  onOrderByTables: PropTypes.func,
  ExportLiboberryGenerator: PropTypes.func,
  orderByReference: PropTypes.func,
  isShowSettingPannel: PropTypes.bool,
  onSetIsShowSettingPannel: PropTypes.func,
  onCheckCitations: PropTypes.func,
  setEditImageElement: PropTypes.func,
  onRefreshProjectFiles: PropTypes.func,
  bindDocumentCitations: PropTypes.func,

  isOpenHeadersPanel: PropTypes.bool,
  onClickHeadersPanel: PropTypes.func,
};

LiboEditor.defaultProps = {
  defaultValue: "",
  onChange: () => {},
  onSelectDivChange: () => {},
  suggestions: [],
  sugs: [],
  plagiarismSugs: [],
  Width: "100%",
  onCheckCitations: () => {},
  onPositionImage: () => {},
  onPositionImageValue: null,
  onOrderByFigures: () => {},
  onOrderByTables: () => {},
  ExportLiboberryGenerator: () => {},
  isShowSettingPannel: false,
  onSetIsShowSettingPannel: () => {},
  orderByReference: () => {},
  setEditImageElement: () => {},
  documentCitation: [],
  editImageElement: null,
  onRefreshProjectFiles: () => {},
  setDocumentCitation: () => {},
  setShowPopupCititions: () => {},
  showPopupCititions: false,
  bindDocumentCitations: () => {},
  isOpenHeadersPanel: false,
  onClickHeadersPanel: () => {},
};

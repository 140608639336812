import "../../components/confirm-alert/confirm-alert.css";
import { Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import BottomMenu from "./component/bottomMenu.js";
import Headers from "./component/headers.js";
import docClasses from "./DocumentPage.module.css";
import { removeSuggestionsFromHTML } from "../../Functions/EditorFunction";
import { ImageDocumentUploadFileAllowMimeType } from "../../Functions/FilterUploadFile";
import {
  DownloadExportFile,
  findReferenceIds,
} from "../../Functions/liboFunction";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Settings from "../../Containers/Settings";

import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import LiboEditor from "../../components/MSKEditor/LiboEditor";
import { confirmAlert } from "react-confirm-alert";

import classesSpin from "../../components/Loader/Loader.module.css";
import { headings } from "../../components/MSKEditor/Components/Headings";

import {
  axiosInstance,
  deleteProjectDocument,
  deleteProjectFile,
  getProject,
  getProjectDocuments,
  getProjectFiles,
  updateProject,
  uploadProjectDocument,
  uploadProjectFile,
  getDocumentCitations,
  exportProject,
} from "../../Requests/Requests";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function DocumentPage(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [oldValue, setOldValue] = useState(null);
  const [projectInfo, setProjectInfo] = useState(null);
  const [projectFiles, setProjectFiles] = useState([]);
  const [projectArticles, setProjectArticles] = useState([]);
  const [openHeader, setOpenHeader] = useState(false);
  const [showPopupCititions, setShowPopupCititions] = useState(false);
  const [showPopupHelp, setShowPopupHelp] = useState(false);
  const [selectDiv, setSelectDiv] = useState({
    id: null,
    line: null,
  });
  const [plagiarismSuggestionHtmlContent, setPlagiarismSuggestionHtmlContent] =
    useState(null);
  const [usageReferences, setUsageReferences] = useState(null);
  const [plagiarismSuggestionArray, setPlagiarismSuggestionArray] = useState(
    []
  );

  const [imgPositionSelect, setImgPositionSelect] = useState(null);
  const [isShowSettingPannel, setIsShowSettingPannel] = useState(false);
  const [documentCitation, setDocumentCitation] = useState([]);
  const [editImageElement, setEditImageElement] = useState(null);
  const DocumentTypes = {
    journalArticle: "journal-article",
    proceedingsArticle: "proceedings-article",
    bookChapter: "book-chapter",
    webPage: "web-page",
  };

  const getShowSettingPanel = () => {
    setIsShowSettingPannel(document.body.offsetWidth > 1112);
  };
  const ConfirmGetSuggestion = () => {
    getProjectDocuments(props.match.params.id)
      .then((res) => {
        let needtoconfirm = false;
        let article_name = "";
        let index = 0;
        for (let i = 0; i < res.data.length; i++) {
          let error = "";
          if (
            res.data[i].documentbibtexdata == null &&
            (res.data[i].state == "parsed" ||
              res.data[i].state == "parse_failed")
          ) {
            needtoconfirm = true;
            error += " Invalid Fields : " + "All";
          } else if (res.data[i].documentbibtexdata != null) {
            if (
              res.data[i].documentbibtexdata.type ==
                DocumentTypes.proceedingsArticle ||
              res.data[i].documentbibtexdata.type ==
                DocumentTypes.journalArticle ||
              res.data[i].documentbibtexdata.type == DocumentTypes.webPage ||
              res.data[i].documentbibtexdata.type == DocumentTypes.bookChapter
            ) {
              if (
                res.data[i].documentbibtexdata.title == null ||
                res.data[i].documentbibtexdata.title.trim() == ""
              ) {
                needtoconfirm = true;
                error += "Title,";
              }
            }
            if (
              res.data[i].documentbibtexdata.type ==
                DocumentTypes.proceedingsArticle ||
              res.data[i].documentbibtexdata.type ==
                DocumentTypes.journalArticle ||
              res.data[i].documentbibtexdata.type == DocumentTypes.bookChapter
            ) {
              if (res.data[i].documentbibtexdata.year == null) {
                needtoconfirm = true;
                error += "Year,";
              }
            }
            if (
              res.data[i].documentbibtexdata.type == DocumentTypes.bookChapter
            ) {
              if (res.data[i].documentbibtexdata.page == null) {
                needtoconfirm = true;
                error += "Page,";
              }
            }
            if (res.data[i].documentbibtexdata.type == DocumentTypes.webPage) {
              if (
                res.data[i].documentbibtexdata.accessed == null ||
                res.data[i].documentbibtexdata.accessed?.trim() == ""
              ) {
                needtoconfirm = true;
                error += "Accessed Date,";
              }
              if (
                res.data[i].documentbibtexdata.publisher == null ||
                res.data[i].documentbibtexdata.publisher?.trim() == ""
              ) {
                needtoconfirm = true;
                error += "Publisher,";
              }
            }

            error = error == "" ? error : " Invalid Fields : " + error;
          } else {
            if (
              res.data[i].state == "waiting" ||
              res.data[i].state == "parsing"
            ) {
              needtoconfirm = true;
              error += "Processing...";
            }
          }
          if (error != "") {
            index++;
            article_name +=
              index + " - " + res.data[i].name + " [" + error + "]\n";
          }
        }
        if (needtoconfirm) {
          confirmAlert({
            title:
              "The information of the articles is not complete. Are you sure you want to Get Suggestion?",
            message: article_name,
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  getSuggestion();
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        } else {
          getSuggestion();
        }
      })
      .catch(() => {
        getSuggestion();
      });
  };
  const createPlagiarismSuggestionResult = (
    suggestionValuelist,
    suggestionValueSimPositive
  ) => {
    for (let suggestionValue of suggestionValuelist) {
      for (let i = 0; i < suggestionValue.length; i++) {
        let item = suggestionValueSimPositive.find(
          (x) => x.doc_id == suggestionValue[i].doc_id
        );
        if (item != null) {
          item.tfidf_sim =
            suggestionValue[i].tfidf_sim > item.tfidf_sim
              ? suggestionValue[i].tfidf_sim
              : item.tfidf_sim;
        }
        if (
          suggestionValue[i].tfidf_sim != null &&
          suggestionValue[i].tfidf_sim != undefined &&
          suggestionValue[i].tfidf_sim > 0 &&
          suggestionValueSimPositive.findIndex(
            (x) => x.doc_id == suggestionValue[i].doc_id
          ) < 0
        ) {
          suggestionValueSimPositive.push(suggestionValue[i]);
        }
      }
    }

    suggestionValueSimPositive.sort(function (a, b) {
      return a.tfidf_sim - b.tfidf_sim;
    });
    suggestionValueSimPositive.reverse();
    return suggestionValueSimPositive;
  };
  let tempbgcolorlist = [];
  let colorPalet = [
    "#F4442E",
    "#FD8E1E",
    "#BD5E8D",
    "#85ff00",
    "#8DA7BE",
    "#A2AD59",
    "#E9E918",
    "#838917",
    "#AFD5AA",
    "#FC9E4F",
    "#8265FF",
    "#B89CC1",
    "#CEB111",
    "#A88B88",
    "#DEC24A",
    "#8BB889",
    "#ffe55e",
    "#F2B509",
    "#33DBDB",
    "#DDE392",
  ];
  const getHtmlPlagiarismSuggestion = (suggestionValueSimPositive) => {
    let htmlRefValidateResult = [];
    for (let i = 0; i < suggestionValueSimPositive.length; i++) {
      let bgcolor = getRandomColor(suggestionValueSimPositive[i].doc_id);

      suggestionValueSimPositive[i].color = bgcolor;

      htmlRefValidateResult.push(
        <div key={i}>
          {" "}
          <div
            style={{
              backgroundColor: suggestionValueSimPositive[i].color,
              marginBottom: "5px",
              textAlign: "left",
              padding: "5px",
              borderRadius: "5px",
              fontSize: "small",
            }}
          >
            <div
              style={{
                fontSize: "11px",
                paddingBottom: "8px",
                fontStyle: "italic",
              }}
            >
              Similarity :{" "}
              {Math.floor(suggestionValueSimPositive[i].tfidf_sim * 100)}%
            </div>
            {suggestionValueSimPositive[i].doc_name}
          </div>
        </div>
      );
    }

    return htmlRefValidateResult;
  };
  function getRandomColor(doc_id) {
    var letters = "0123456789ABCDEF";
    var color = "#";
    if (colorPalet.length <= 0) {
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } else {
      color = colorPalet[0];
    }

    if (tempbgcolorlist.length > 0) {
      let sug_doc = tempbgcolorlist.find((s) => s.doc_id == doc_id);
      if (sug_doc != null) {
        color = sug_doc.color;
      } else {
        if (colorPalet.length > 0) {
          colorPalet.splice(0, 1);
        }
        tempbgcolorlist.push({ doc_id: doc_id, color: color });
      }
    } else {
      if (colorPalet.length > 0) {
        colorPalet.splice(0, 1);
      }
      tempbgcolorlist.push({ doc_id: doc_id, color: color });
    }
    return color;
  }

  const setPlagiarismSuggestion = (sugs) => {
    const newSugs = sugs;
    let htmlRefValidateResult = [];
    let suggestionValueSimPositive = [];
    tempbgcolorlist = [];

    for (const sug of newSugs) {
      const targets = [...document.querySelectorAll(`#${sug.id}`)];
      if (sug.suggestions == null || sug.suggestions.length == 0) {
        continue;
      }
      if (targets.length > 0) {
        for (const target of targets) {
          let node = target;
          while (
            node != null &&
            node.tagName != "ARTICLE" &&
            !(node.tagName == "DIV" && node.parentNode?.tagName == "ARTICLE") &&
            !(
              node.hasAttribute("caption") ||
              node.hasAttribute("tablecaption") ||
              node.hasAttribute("heading")
            )
          ) {
            node = node.parentNode;
          }
          if (
            node != null &&
            ((node.hasAttribute("heading") &&
              node.getAttribute("heading") != "abstract") ||
              node.hasAttribute("caption") ||
              node.hasAttribute("tablecaption"))
          ) {
            continue;
          }

          let html = target.innerHTML.trim();
          html = html.replace(/(<div).*?(<\/div>)/g, html);
          var tempobj = document.createElement("DIV");
          tempobj.innerHTML = html;

          if (tempobj.innerText.trim() != "") {
            try {
              suggestionValueSimPositive = createPlagiarismSuggestionResult(
                sug.suggestions,
                suggestionValueSimPositive
              );
            } catch {}
          }
        }
      }
    }
    htmlRefValidateResult = getHtmlPlagiarismSuggestion(
      suggestionValueSimPositive
    );

    setPlagiarismSuggestionArray(suggestionValueSimPositive);
    setPlagiarismSuggestionHtmlContent(htmlRefValidateResult);
    setPlagiarismSugs(sugs);
  };
  const getPlagiarismSuggestion = () => {
    axiosInstance
      .get("/ai/" + props.match.params.id + "/get_paraphraser_suggestions/")
      .then((res) => {
        console.log("get_paraphraser_suggestions");
        console.log(res.data.suggests);
        if (
          res.data.suggests == null ||
          res.data.suggests == undefined ||
          res.data.suggests.length == 0
        ) {
          toast.success("Nice! No concordance found!");
          setPlagiarismSuggestion([]);
        } else {
          setPlagiarismSuggestion(res.data.suggests);
        }
      })
      .catch((err) => {
        toast.warn(
          "Ops! Seems like there is a problem with the server. Please check the Knowledge Management."
        );
        console.log("err.message", err.message);
        console.log("err", err);
      });
  };
  const getSuggestion = () => {
    if (selectDiv.id == null) {
      toast.warn("Click on the line you want suggestions for");
      return;
    } else {
      try {
        let nodes = getEditorElement().querySelectorAll("#" + selectDiv.id);
        if (nodes != null && nodes.length == 1) {
          let node = nodes[0];
          while (node != null && node.tagName != "ARTICLE") {
            if (
              node != null &&
              node.tagName == "DIV" &&
              node.hasAttribute("heading")
            ) {
              let nodeValue = node.getAttribute("heading");
              if (
                nodeValue != null &&
                nodeValue != undefined &&
                nodeValue != "" &&
                nodeValue != "div"
              ) {
                toast.error(
                  headings.find((s) => s.value == nodeValue).title +
                    "  is not citable "
                );
                return;
              }
            }
            node = node.parentNode;
          }
        }
      } catch {}
    }
    axiosInstance
      .post("/ai/" + props.match.params.id + "/get_recommender_suggestions/", {
        div_id: selectDiv.id,
      })
      .then((res) => {
        console.log("recieving suggestion", res.data.suggests);
        setSugs({
          id: selectDiv.id,
          line: selectDiv.line,
          suggestion: res.data.suggests,
        });
      })
      .catch((err) => {
        //toast.error("Please Click on row that you want get suggestion");
        setSelectDiv({ id: null, line: null });
        toast.warn(
          "Ops! Seems like there is a problem with the server. Please Try Again."
        );
        setSugs({
          id: selectDiv.id,
          line: selectDiv.line,
          suggestion: [],
        });
        // toast.error(err.message);
        console.log("err.message", err.message);
        console.log("err", err);
      });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleUploadProjectFiles = (files, resetSelectedFiles) => {
    resetSelectedFiles();
    for (const article of files) {
      if (ImageDocumentUploadFileAllowMimeType(article.type)) {
        uploadProjectFile(props.match.params.id, article)
          .then(() => {
            refreshProjectFiles();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleUploadProjectArticles = (articles, resetSelectedArticles) => {
    resetSelectedArticles();
    for (const article of articles) {
      uploadProjectDocument(props.match.params.id, article)
        .then(() => {
          refreshProjectDocuments();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const ExportLiboberryGenerator = (type) => {
    orderByReference();
    getAllUsageReferenceInDocument();
    orderByFigures();
    orderByTables();
    autoSave(getEditorElement().innerHTML);

    let IdsList = findReferenceIds(getEditorElement().innerHTML);
    let ids = IdsList.length > 0 ? IdsList.toString(0) : "";
    let exportInfo = {
      doc_ids: ids,
      export_type: type,
    };

    exportProject(props.match.params.id, exportInfo)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message) {
            toast.error(response.data.message);
          } else {
            // Check the response headers for the 'Content-Disposition' to get the filename
            const contentDisposition = response.headers["content-disposition"];
            let filename = projectInfo?.title;
            let headerContentType = response.headers["content-type"];
            let data = response.data;
            DownloadExportFile(
              type,
              filename,
              contentDisposition,
              headerContentType,
              data
            );
          }
        } else {
          console.error("Error:", response.data.Message);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleDeleteProjectFile = (id) => {
    deleteProjectFile(props.match.params.id, id)
      .then(() => {
        refreshProjectFiles();
        deleteFigureFromEditor(id);
      })
      .catch(() => {
        toast.error("An error occurred while deleting the file!");
      });
  };
  const checkCitations = () => {
    orderByFigures();
    orderByTables();
    autoSave(getEditorElement().innerHTML, ConfirmGetSuggestion);
  };
  const deleteFigureFromEditor = (id) => {
    let Figures = getEditorElement().getElementsByClassName("Figure_" + id);

    let txtArray = [];
    let removeArray = [];
    for (let fig of Figures) {
      let text = fig.innerText.trim();
      txtArray.push(text);
      removeArray.push(fig.parentNode);
    }
    for (var reomveEl of removeArray) {
      reomveEl.remove();
    }

    orderByFigures();
  };
  const handleDeleteProjectArticle = (id) => {
    deleteProjectDocument(props.match.params.id, id)
      .then(() => {
        refreshProjectDocuments();
      })
      .catch(() => {
        toast.error("An error occurred while deleting the document!");
      });
  };

  const refreshProjectFiles = () => {
    getProjectFiles(props.match.params.id)
      .then((res) => {
        setProjectFiles(res.data);
      })
      .catch(() => {
        toast.error("An error occurred while getting the project files!");
      });
  };

  const refreshProjectDocuments = (issetvalue) => {
    getProjectDocuments(props.match.params.id)
      .then((res) => {
        setProjectArticles(res.data);
        if (issetvalue == true) setValue(res.data.length > 0 ? 0 : 1);
      })
      .catch(() => {
        toast.error("An error occurred while getting the document!");
      });
  };

  const refreshProjectInfo = () => {
    getProject(props.match.params.id)
      .then((res) => {
        setProjectInfo(res.data);
        setOldValue(res.data.content);
        bindDocumentCitations(res.data.citation_style);
      })
      .catch(() => {
        toast.error("An error occurred while getting the project info!");
      });
  };

  useEffect(() => {
    getShowSettingPanel();
    refreshProjectInfo();
    refreshProjectFiles();
    refreshProjectDocuments(true);
  }, []);

  const CreateCitationFormatWithCitation_style = (
    Index,
    citation_string,
    citation_style
  ) => {
    switch (citation_style) {
      case "ieee":
        citation_string = "[" + Index + "]" + citation_string;
        break;
      case "vancouver":
        citation_string = Index + ". " + citation_string;
        break;
      case "apa":
        citation_string = citation_string;
        break;
      case "chicago":
        citation_string = citation_string;
        break;
      case "mla":
        citation_string = citation_string;
        break;
      default:
        break;
    }
    return citation_string.trim();
  };
  const bindDocumentCitations = (citation_style = null) => {
    getDocumentCitations(props.match.params.id, { style: citation_style })
      .then((res) => {
        // console.log(res.data);
        // console.log(res.data.citations);
        let refs = getEditorElement().getElementsByTagName("ref");
        let citations = res.data.citations;
        setDocumentCitation(citations);
        if (
          citations != null &&
          citations != undefined &&
          citations.length > 0
        ) {
          for (let ref of refs) {
            ref.removeAttribute("title");
            ref.removeAttribute("bibtex");
            if (
              ref.className != null &&
              ref.className != undefined &&
              ref.className.startsWith("class_ref")
            ) {
              const refId = ref.className.replace("class_ref", "");
              let result = citations.find((x) => x.doc_id == refId);
              if (result != null && result != undefined) {
                ref.setAttribute("title", result.citation_string);
                ref.setAttribute("bibtex", result.bibtex);
              }
            }
          }
        } else if (
          citations != null &&
          citations != undefined &&
          citations.length == 0
        ) {
          for (let ref of refs) {
            ref.removeAttribute("title");
            ref.removeAttribute("bibtex");
          }
        }
        orderByReference();
        getAllUsageReferenceInDocument(citation_style);
      })
      .catch((err) => {
        console.log(err);
        setDocumentCitation([]);
      });
  };
  const getEditorWordCount = () => {
    let obj = document.createElement("div");
    obj.innerHTML = getEditorElement()?.innerHTML;
    let imges = obj.querySelectorAll("div[caption]");
    let tables = obj.querySelectorAll("div[tablecaption]");
    let headings = obj.querySelectorAll("div[heading]");
    let formules = obj.querySelectorAll("LIBOMATH");
    let refrences = obj.querySelectorAll("REFERENCES");
    for (let img of imges) {
      img.remove();
    }
    for (let table of tables) {
      table.remove();
    }
    for (let formule of formules) {
      formule.remove();
    }
    for (let refrence of refrences) {
      refrence.remove();
    }
    for (let heading of headings) {
      if (heading.getAttribute("heading") != "div") {
        heading.remove();
      }
    }

    return obj.innerText
      .trim()
      .split(" ")
      .filter((s) => s != null && s.trim() != "" && s != " " && s.length > 0)
      .length;
  };
  const getEditorElement = () => {
    return document.getElementById("editor");
  };
  const getAllUsageReferenceInDocument = (citation_style = null) => {
    let ReferenceIdList = [];
    let ReferenceTitleList = [];
    let refs = getEditorElement().getElementsByTagName("ref");
    let counter = 0;
    citation_style =
      citation_style == null ? projectInfo?.citation_style : citation_style;

    for (let ref of refs) {
      if (
        ref.className != null &&
        ref.className != undefined &&
        ref.className.startsWith("class_ref")
      ) {
        const refId = ref.className.replace("class_ref", "");
        if (
          ref.title != null &&
          ref.title != undefined &&
          ref.title.trim() != ""
        ) {
          if (
            ReferenceIdList == null ||
            ReferenceIdList.length == 0 ||
            ReferenceIdList.findIndex((x) => x == refId) == -1
          ) {
            counter++;
            ReferenceIdList.push(refId);
            ReferenceTitleList.push(
              <>
                <div style={{ marginBottom: "8px" }}>
                  {CreateCitationFormatWithCitation_style(
                    counter,
                    ref.title,
                    citation_style
                  )}
                </div>
              </>
            );
          }
        }
      }
    }
    setUsageReferences(ReferenceTitleList);
  };
  const orderByFigures = () => {
    try {
      let figures = getEditorElement().getElementsByTagName("imgFigure");
      let index = 0;
      let sortFig = [];
      let removeElements = [];
      for (let fig of figures) {
        let div = fig.parentNode;
        let divParrent = fig.parentNode?.parentNode;
        if (div.tagName == "DIV" && div.getAttribute("caption")) {
          if (fig.parentNode.getElementsByTagName("img").length == 0) {
            removeElements.push(fig.parentNode);
            continue;
          }
        } else if (
          divParrent?.tagName == "DIV" &&
          divParrent?.getAttribute("caption")
        ) {
          if (divParrent.getElementsByTagName("img").length == 0) {
            removeElements.push(divParrent);
            continue;
          } else if (div.tagName == "DIV") {
            div.outerHTML = div.innerHTML;
          }
        }
        index++;
        sortFig.push({
          index: index,
          oldvalue: fig.innerText.trim(),
          newvalue: "Figure-" + index,
        });
        fig.innerHTML = "Figure-" + index + " ";
        // if (fig.parentNode.tagName == "DIV" && fig.parentNode.getAttribute('caption')) {
        //     // fig.parentNode.setAttribute('caption', 'true');
        //     fig.parentNode.style.textAlign = 'center';
        // }
        // else if ((fig.parentNode.tagName == "DIV" && !fig.parentNode.getAttribute('caption'))
        //     && (fig.parentNode?.parentNode.tagName == "DIV" && fig.parentNode?.parentNode?.getAttribute('caption'))) {
        //     // fig.parentNode.setAttribute('caption', 'true');
        //     fig.parentNode.outerHTML = fig.parentNode.innerHTML;
        // }
      }
      for (var reomveEl of removeElements) {
        reomveEl.remove();
      }
      let imgbloks = getEditorElement().getElementsByTagName("IMGBLOCK");
      for (let imgblock of imgbloks) {
        let node = imgblock;
        while (
          node != null &&
          node.tagName != "DIV" &&
          node.tagName != "ARTICLE"
        ) {
          node = node.parentNode;
        }
        if (
          node != null &&
          node.tagName == "DIV" &&
          node.hasAttribute("caption")
        ) {
          let editElements = imgblock.getElementsByTagName("editImagesBtn");
          if (editElements == null || editElements.length == 0) {
            editElements = document.createElement("editImagesBtn");
            imgblock.append(editElements);
          } else {
            editElements = editElements[0];
          }
          editElements.innerHTML =
            '<svg viewBox="0 0 24 24" aria-hidden="true"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>';
          editElements.setAttribute("title", "Edit");
          editElements.addEventListener("click", handleEditImage);
        }
      }
    } catch {}
  };
  const handleEditImage = function () {
    let imgElements = this.parentNode.getElementsByTagName("img");
    if (imgElements != null && imgElements.length > 0) {
      setEditImageElement(imgElements[0]);
    }
  };

  const orderByTables = () => {
    try {
      let tablenumbers = getEditorElement().getElementsByTagName("tablenumber");
      let index = 0;
      let sortnumber = [];
      let removeArray = [];
      for (let tablenumber of tablenumbers) {
        if (tablenumber.parentNode.getElementsByTagName("table").length == 0) {
          removeArray.push(tablenumber.parentNode);
          continue;
        }

        index++;
        sortnumber.push({
          index: index,
          oldvalue: tablenumber.innerText.trim(),
          newvalue: "Table-" + index,
        });
        tablenumber.innerHTML = "Table-" + index + " ";
        if (tablenumber.parentNode.tagName == "DIV") {
          tablenumber.parentNode.setAttribute("tablecaption", "true");
          tablenumber.parentNode.style.textAlign = "center";
        }
      }
      for (var reomveEl of removeArray) {
        reomveEl.remove();
      }
    } catch {}
  };
  const orderByReference = () => {
    try {
      let References = getEditorElement().getElementsByTagName("references");
      let refFind = [];
      let refElementRemove = [];
      if (References != null && References.length > 0) {
        for (let i = 0; i < References.length; i++) {
          let IsrefElementRemove = true;
          let refs = References[i].getElementsByTagName("ref");
          if (refs != null && refs.length > 0) {
            for (let j = 0; j < refs.length; j++) {
              if (
                refs[j].innerText.trim() == "" ||
                refs[j].title == null ||
                refs[j].title == undefined ||
                refs[j].title.trim() == ""
              ) {
                refElementRemove.push(refs[j]);
              } else {
                IsrefElementRemove = false;

                let index = refFind.indexOf(
                  refs[j].className.replace("class_ref", "")
                );
                if (index < 0) {
                  refFind.push(refs[j].className.replace("class_ref", ""));
                }
              }
            }
          }
          if (IsrefElementRemove) {
            refElementRemove.push(References[i]);
          }
        }
      }
      for (let i = 0; i < refElementRemove.length; i++) {
        refElementRemove[i].remove();
      }
      for (let i = 0; i < refFind.length; i++) {
        let refList = getEditorElement().getElementsByClassName(
          "class_ref" + refFind[i]
        );

        for (let j = 0; j < refList.length; j++) {
          refList[j].innerHTML = i + 1;
        }
      }

      let ReferencesOrder =
        getEditorElement().getElementsByTagName("references");
      for (let i = 0; i < ReferencesOrder.length; i++) {
        var newRefrense = document.createElement("references");
        let refs = References[i].getElementsByTagName("ref");
        newRefrense.appendChild(document.createTextNode("["));

        for (let j = 0; j < refs.length; j++) {
          let elnode = refs[j];
          // console.log(elnode);
          // console.log(elnode.getAttribute('bibtex'))
          let node1 = Number(refs[j].innerHTML);
          let elJ = document.createElement("ref");
          elJ.className = elnode.className;
          elJ.title = elnode.title;
          elJ.setAttribute("bibtex", elnode.getAttribute("bibtex"));
          elJ.innerHTML = elnode.innerHTML;

          let el = document.createElement("ref");
          el.className = elnode.className;
          el.innerHTML = elnode.innerHTML;
          el.title = elnode.title;
          el.setAttribute("bibtex", elnode.getAttribute("bibtex"));

          for (let k = j + 1; k < refs.length; k++) {
            let node2 = Number(refs[k].innerHTML);
            if (node1 > node2) {
              node1 = node2;
              elnode = refs[k];

              let elK = document.createElement("ref");
              elK.className = elnode.className;
              elK.innerHTML = elnode.innerHTML;
              elK.title = elnode.title;
              elK.setAttribute("bibtex", elnode.getAttribute("bibtex"));

              el.className = elnode.className;
              el.innerHTML = elnode.innerHTML;
              el.title = elnode.title;
              el.setAttribute("bibtex", elnode.getAttribute("bibtex"));
              refs[j].replaceWith(elK);
              refs[k].replaceWith(elJ);
            }
          }

          if (j == 0) {
            newRefrense.appendChild(el);
          } else {
            newRefrense.appendChild(document.createTextNode(","));
            newRefrense.appendChild(el);
          }
        }

        newRefrense.appendChild(document.createTextNode("]"));

        References[i].replaceWith(newRefrense);
      }
    } catch {}
  };
  const autoSave = (value, afterSaveCall = () => {}) => {
    if (!value || value.length === 0) return;

    let newHTML = removeSuggestionsFromHTML(value);

    const el = document.createElement("div");
    el.innerHTML = newHTML;

    const paragraphs = [...el.querySelectorAll("div")];
    for (const p of paragraphs) {
      p.removeAttribute("class");
      p.removeAttribute("hashid");
      p.style.backgroundColor = null;
      p.removeAttribute("data-sug");
      p.removeAttribute("data-plg");
    }

    if (oldValue != null && oldValue == el.innerHTML) {
      afterSaveCall();
      return;
    }

    setOldValue(el.innerHTML);
    updateProject(props.match.params.id, { content: el.innerHTML })
      .then((res) => {
        afterSaveCall();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const debouncedSave = useCallback(
  //   debounce((nextValue) => autoSave(nextValue), 100),
  //   []
  // );

  // let testInterval;
  //
  // useEffect(() =)

  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    getProject(props.match.params.id)
      .then((res) => {
        setDefaultValue(res.data.content);
        setOldValue(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [sugs, setSugs] = useState([]);
  const [plagiarismSugs, setPlagiarismSugs] = useState([]);

  return (
    <div style={{ display: "flex", height: "calc(100% - 70px)" }}>
      <BottomMenu
        isShowSettingPannel={isShowSettingPannel}
        BibliographyClick={() => {
          orderByReference();
          getAllUsageReferenceInDocument();
          orderByFigures();
          orderByTables();
          autoSave(getEditorElement().innerHTML);
        }}
        CitationsClick={() => {
          setShowPopupCititions(true);
        }}
        BibliographyValue={usageReferences}
        getEditorWordCount={getEditorWordCount}
        projectInfo={projectInfo}
        setShowPopupHelp={setShowPopupHelp}
        showPopupHelp={showPopupHelp}
      />

      <Headers
        open={openHeader}
        setOpen={setOpenHeader}
        html={getEditorElement()?.innerHTML}
      />
      <div style={{ flex: 1 }}>
        <LiboEditor
          bindDocumentCitations={() => {
            bindDocumentCitations(projectInfo?.citation_style);
          }}
          isOpenHeadersPanel={openHeader}
          onClickHeadersPanel={() => {
            setOpenHeader(!openHeader);
          }}
          setShowPopupCititions={setShowPopupCititions}
          showPopupCititions={showPopupCititions}
          editImageElement={editImageElement}
          setEditImageElement={setEditImageElement}
          documentCitation={documentCitation}
          setDocumentCitation={setDocumentCitation}
          onRefreshProjectFiles={() => {
            refreshProjectFiles();
          }}
          // key={sugs.join('...')}
          onChange={(html) => {
            autoSave(html);
          }}
          onOrderByTables={() => {
            try {
              orderByTables();
            } catch {}
          }}
          onOrderByFigures={() => {
            try {
              orderByFigures();
            } catch {}
          }}
          onCheckCitations={checkCitations}
          onPositionImage={setImgPositionSelect}
          onPositionImageValue={imgPositionSelect}
          onSelectDivChange={(div_id, lineindex) => {
            setSelectDiv({
              id: div_id,
              line: lineindex,
            });
          }}
          plagiarismSuggestionContent={plagiarismSuggestionArray}
          orderByReference={() => {
            orderByReference();
            getAllUsageReferenceInDocument();
          }}
          ExportLiboberryGenerator={ExportLiboberryGenerator}
          defaultValue={defaultValue}
          sugs={sugs}
          Width={isShowSettingPannel ? "calc(100% - 320px)" : "100%"}
          plagiarismSugs={plagiarismSugs}
          projectInfo={projectInfo}
          isShowSettingPannel={isShowSettingPannel}
          onSetIsShowSettingPannel={setIsShowSettingPannel}
        />
      </div>

      <Paper
        id={"paperSettingPannel"}
        className={
          isShowSettingPannel
            ? classesSpin.openVerticalAnimation
            : classesSpin.closeVerticalAnimation
        }
        style={{
          height: "calc(100vh - 65px)",
          zIndex: "1",
          maxWidth: "320px",
          position: "fixed",
          right: "0px",
        }}
      >
        <div
          className={classes.root + " " + classesSpin.boardslist}
          style={{
            zIndex: "1",
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div className={docClasses.btnPanelBox}>
            <div
              onClick={() => {
                setIsShowSettingPannel(!isShowSettingPannel);
              }}
              className={docClasses.btnPanel}
            >
              {isShowSettingPannel ? (
                <ArrowRightIcon
                  style={{ color: "white" }}
                  fontSize={"medium"}
                />
              ) : (
                <ArrowLeftIcon style={{ color: "white" }} fontSize={"medium"} />
              )}
            </div>
          </div>
          <AppBar position="sticky">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                style={{
                  textTransform: "none",
                  background: "white",
                  color: "#444",
                }}
                label="Suggestions"
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  textTransform: "none",
                  background: "white",
                  color: "#444",
                }}
                label="Settings"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} key="Suggestions">
            <div
              style={{
                width: "320px",
                margin: "-10px -24px",
                paddingBottom: "40px",
              }}
            >
              <Button
                onClick={() => {
                  autoSave(
                    getEditorElement().innerHTML,
                    getPlagiarismSuggestion
                  );
                }}
                style={{
                  color: "black",
                  textTransform: "none",
                  backgroundColor: "#DDD",
                  width: "calc(100% - 20px)",
                  margin: "20px 10px 10px 10px",
                }}
              >
                Check plagiarism
              </Button>
              {plagiarismSuggestionHtmlContent}
            </div>
          </TabPanel>
          {/* ------------------------------ Settings -------------------------- */}
          <TabPanel value={value} index={1} key="Settings">
            <Settings
              // projectId={props}
              onOrderByFigures={() => {
                orderByFigures();
                autoSave(getEditorElement().innerHTML);
              }}
              onPositionImage={setImgPositionSelect}
              onPositionImageValue={imgPositionSelect}
              projectFiles={projectFiles}
              projectId={props.match.params.id}
              onFilesUploadButtonClick={handleUploadProjectFiles}
              onArticlesUploadButtonClick={handleUploadProjectArticles}
              onProjectFileDelete={handleDeleteProjectFile}
              onProjectArticleDelete={handleDeleteProjectArticle}
              articles={projectArticles}
              projectInfoValue={projectInfo}
              setProjectInfo={setProjectInfo}
              updateProject={updateProject}
              onChange={(citation_style) => {
                orderByReference();
                bindDocumentCitations(citation_style);
                orderByTables();
                orderByFigures();
                autoSave(getEditorElement().innerHTML);
              }}
              onSave={(func) => {
                orderByReference();
                orderByTables();
                orderByFigures();
                autoSave(getEditorElement().innerHTML);
              }}
            />
          </TabPanel>
        </div>
      </Paper>
    </div>
  );
}

export default withRouter(DocumentPage);
